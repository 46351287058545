import React from 'react';
import PropTypes from 'prop-types';
import { getScrollbarWidth } from 'mastodon/utils/scrollbar';
import Base from 'mastodon/components/modal_root';
import BundleContainer from '../containers/bundle_container';
import BundleModalError from './bundle_modal_error';
import ModalLoading from './modal_loading';
import ActionsModal from './actions_modal';
import MediaModal from './media_modal';
import VideoModal from './video_modal';
import BoostModal from './boost_modal';
import AudioModal from './audio_modal';
import ConfirmationModal from './confirmation_modal';
import FocalPointModal from './focal_point_modal';
import ImageModal from './image_modal';
import {
  MuteModal,
  BlockModal,
  ReportModal,
  EmbedModal,
  ListEditor,
  ListAdder,
  CompareHistoryModal,
  FilterModal,
  InteractionModal,
  SubscribedLanguagesModal,
  ClosedRegistrationsModal,
  ConnectWalletModal,
  DisconnectWalletModal,
} from 'mastodon/features/ui/util/async-components';
import { Helmet } from 'react-helmet';
import MegaBoostModal from 'mastodon/components/mega_boost_modal';
import ConfirmationMegaBoostModal from 'mastodon/components/confirmation_mega_boost_modal';
import ConfirmationClaimRewardModal from 'mastodon/components/confirmation_claim_reward_modal';

const MODAL_COMPONENTS = {
  MEDIA: () => Promise.resolve({ default: MediaModal }),
  VIDEO: () => Promise.resolve({ default: VideoModal }),
  AUDIO: () => Promise.resolve({ default: AudioModal }),
  IMAGE: () => Promise.resolve({ default: ImageModal }),
  BOOST: () => Promise.resolve({ default: BoostModal }),
  MEGABOOST: () => Promise.resolve({ default: MegaBoostModal }),
  CONFIRMATION_MEGABOOST: () =>
    Promise.resolve({ default: ConfirmationMegaBoostModal }),
  CONFIRMATION_CLAIM_REWARD: () =>
    Promise.resolve({ default: ConfirmationClaimRewardModal }),
  CONFIRM: () => Promise.resolve({ default: ConfirmationModal }),
  MUTE: MuteModal,
  BLOCK: BlockModal,
  REPORT: ReportModal,
  ACTIONS: () => Promise.resolve({ default: ActionsModal }),
  EMBED: EmbedModal,
  LIST_EDITOR: ListEditor,
  FOCAL_POINT: () => Promise.resolve({ default: FocalPointModal }),
  LIST_ADDER: ListAdder,
  COMPARE_HISTORY: CompareHistoryModal,
  FILTER: FilterModal,
  SUBSCRIBED_LANGUAGES: SubscribedLanguagesModal,
  INTERACTION: InteractionModal,
  CLOSED_REGISTRATIONS: ClosedRegistrationsModal,
  CONNECT_WALLET: ConnectWalletModal,
  DISCONNECT_WALLET: DisconnectWalletModal,
};

export default class ModalRoot extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    props: PropTypes.object,
    onClose: PropTypes.func,
    ignoreFocus: PropTypes.bool,
    signedIn: PropTypes.bool,
    disabledAccountId: PropTypes.string,
  };

  state = {
    backgroundColor: null,
    canCloseInOverlay: true,
  };

  getSnapshotBeforeUpdate() {
    return { visible: !!this.props.type };
  }

  componentDidUpdate(prevProps, prevState, { visible }) {
    if (visible) {
      document.body.classList.add('with-modals--active');
      document.documentElement.style.marginRight = `${getScrollbarWidth()}px`;
    } else {
      document.body.classList.remove('with-modals--active');
      document.documentElement.style.marginRight = 0;
    }
    this.setState({
      canCloseInOverlay:
        this.props?.props?.canCloseInOverlay !== undefined
          ? this.props?.props?.canCloseInOverlay
          : true,
    });
  }

  setBackgroundColor = (color) => {
    this.setState({ backgroundColor: color });
  };

  renderLoading = (modalId) => () => {
    return ['MEDIA', 'VIDEO', 'BOOST', 'CONFIRM', 'ACTIONS'].indexOf(
      modalId
    ) === -1 ? (
      <ModalLoading />
    ) : null;
  };

  renderError = (props) => {
    const { onClose } = this.props;

    return <BundleModalError {...props} onClose={onClose} />;
  };

  handleClose = (ignoreFocus = false) => {
    const { onClose } = this.props;
    let message = null;
    try {
      message = this._modal
        ?.getWrappedInstance?.()
        .getCloseConfirmationMessage?.();
    } catch (_) {
      // injectIntl defines `getWrappedInstance` but errors out if `withRef`
      // isn't set.
      // This would be much smoother with react-intl 3+ and `forwardRef`.
    }
    this.setState({ canCloseInOverlay: true });
    onClose(message, ignoreFocus);
  };

  setModalRef = (c) => {
    this._modal = c;
  };

  render() {
    const { type, props, ignoreFocus } = this.props;
    const { backgroundColor, canCloseInOverlay } = this.state;
    const visible = !!type;

    return (
      <Base
        backgroundColor={backgroundColor}
        onClose={canCloseInOverlay ? this.handleClose : null}
        ignoreFocus={ignoreFocus}
      >
        {visible && (
          <>
            <BundleContainer
              fetchComponent={MODAL_COMPONENTS[type]}
              loading={this.renderLoading(type)}
              error={this.renderError}
              renderDelay={200}
            >
              {(SpecificComponent) => (
                <SpecificComponent
                  {...props}
                  {...this.props}
                  onChangeBackgroundColor={this.setBackgroundColor}
                  onClose={this.handleClose}
                  ref={this.setModalRef}
                />
              )}
            </BundleContainer>

            <Helmet>
              <meta name='robots' content='noindex' />
            </Helmet>
          </>
        )}
      </Base>
    );
  }
}
