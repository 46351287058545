import React, { useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from './button';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useWeb3React } from '@web3-react/core';
import { loadingIcon } from 'mastodon/utils/icons';
import { getMegaBoostContract } from 'mastodon/utils/contract';
import { showAlert } from 'mastodon/actions/alerts';
import { useDispatch } from 'react-redux';
import { closeModal } from 'mastodon/actions/modal';
import { MPX_DECIMAL } from 'mastodon/utils/numbers';
import api from 'mastodon/api';
import { getMPXBalance } from 'mastodon/actions/mpx_balance';
import MPXABI from 'mastodon/abis/MindplexUpgradeableToken.json';
import { fetchClaimedAndRewarded } from 'mastodon/actions/getClaimInfo';
import {
  NAMI_REJECT_ERROR_CODE,
  buildSendADATransaction,
  useAppCardano,
} from 'mastodon/containers/cardano_context';
import { BigNumber } from '@ethersproject/bignumber';
import { TransactionRejected } from './confirmation_mega_boost_modal';

const messages = defineMessages({
  yes: {
    id: 'mega_boost_modal.yes',
    defaultMessage: 'Yes',
  },
  confirm_claim_reward: {
    id: 'reward.confirm_claim_reward',
    defaultMessage: 'Do you want to claim the rewards of ',
  },
  claiming: {
    id: 'reward.claiming',
    defaultMessage: 'Claiming',
  },
});

function ConfirmationClaimRewardModal({ intl, userAccount, claimable }) {
  const dispatch = useDispatch();
  const { library, account, active } = useWeb3React();
  const {
    isEnabled,
    usedAddresses,
    getCardanoMpxBalance,
    provider,
    currentWallet,
  } = useAppCardano();
  const [isClaiming, setIsClaiming] = useState(false);

  const accountId = userAccount?.get('id');

  const onClose = () => {
    dispatch(closeModal('CONFIRMATION_CLAIM_REWARD'));
  };

  function handleShowAlert(message) {
    dispatch(showAlert('', message));
  }

  const network = active ? 'ether' : isEnabled ? 'cardano' : '';
  const getSignature = async () => {
    try {
      if (!network || (!active && !isEnabled)) return null;

      const signatureRes = await api().get(
        `/api/v1/accounts/${accountId}/claim_signature`,
        {
          params: {
            wallet_address: account,
            network,
            currency: 'mpx',
          },
        }
      );

      return signatureRes;
    } catch (err) {
      dispatch(showAlert(err?.response?.data?.errors[0], ''));
      throw err;
    }
  };

  const tryClaimRewardInETHContract = async ({
    orderId,
    validUntil,
    signature,
  }) => {
    try {
      const megaBoostContract = getMegaBoostContract(library);
      await megaBoostContract.methods
        .claimReward(
          userAccount.get('id'),
          claimable,
          account,
          orderId,
          validUntil,
          signature
        )
        .send({
          from: account,
        });
    } catch (err) {
      if (err.code === 4001) {
        dispatch(showAlert('Transaction reject', ''));
      } else {
        dispatch(showAlert('Transaction error', ''));
      }
      throw err;
    }
  };

  const handleEthClaimRewards = async () => {
    const signatureRes = await getSignature();
    const data = signatureRes.data;

    await tryClaimRewardInETHContract({
      orderId: data.balance_history_id,
      validUntil: data.expired_at,
      signature: data.data,
    });

    dispatch(getMPXBalance({ library, mpxAbi: MPXABI, account }));
  };

  const handleCardanoClaimRewards = async () => {
    try {
      if (!usedAddresses || !usedAddresses.length) {
        throw new Error();
      }

      const signatureRes = await getSignature();
      const { address_admin_wallet, carnado_transaction_fee } =
        signatureRes.data;

      const submittedTxHash = await buildSendADATransaction(
        provider,
        currentWallet,
        address_admin_wallet,
        usedAddresses?.[0],
        carnado_transaction_fee
      );

      await api().post(`/api/v1/accounts/${accountId}/create_claim_order`, {
        wallet_address: account,
        network,
        currency: 'mpx',
        claim_fee_transaction: submittedTxHash,
        note: {
          amount: BigNumber.from(carnado_transaction_fee).mul(1e6).toString(),
          recipient: address_admin_wallet,
          sender: usedAddresses?.[0],
        },
      });

      await getCardanoMpxBalance();
    } catch (error) {
      if (error.code === NAMI_REJECT_ERROR_CODE) {
        dispatch(showAlert('Transaction reject', ''));
      } else if (error instanceof TransactionRejected) {
        handleShowAlert('Transaction reject', '');
      } else {
        dispatch(showAlert('Transaction error', ''));
      }
      throw error;
    }
  };

  const onConfirm = async () => {
    try {
      setIsClaiming(true);
      if (active) {
        await handleEthClaimRewards();
      } else if (isEnabled) {
        await handleCardanoClaimRewards();
      }

      dispatch(
        fetchClaimedAndRewarded({ accountId, network, currency: 'mpx' })
      );
      dispatch(showAlert('Transaction complete', ''));
    } finally {
      onClose();
      setIsClaiming(false);
    }
  };

  return (
    <div className='modal-root__modal verify-modal confirm-mega-boost-modal'>
      <div className='confirm-mega-boost-modal__message'>
        {isClaiming ? (
          <div>
            <p className='loading-icon'>{loadingIcon}</p>
            <p>{intl.formatMessage(messages.claiming)}</p>
          </div>
        ) : (
          <p>
            {intl.formatMessage(messages.confirm_claim_reward)}
            {claimable / MPX_DECIMAL} MPX?
          </p>
        )}
      </div>

      <div className='modal-root__action-bar verify-modal__footer'>
        <Button
          onClick={onClose}
          className='verify-modal__footer-cancel'
          disabled={isClaiming}
        >
          <FormattedMessage
            id='confirmation_modal.cancel'
            defaultMessage='Cancel'
          />
        </Button>
        <Button
          disabled={isClaiming}
          text={intl.formatMessage(messages.yes)}
          onClick={onConfirm}
          className='modal-root__cancel-button verify-modal__footer-confirm'
        />
      </div>
    </div>
  );
}

ConfirmationClaimRewardModal.propTypes = {
  intl: PropTypes.object.isRequired,
  userAccount: ImmutablePropTypes.map,
  claimable: PropTypes.number.isRequired,
};

export default injectIntl(ConfirmationClaimRewardModal);
