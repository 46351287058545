import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchLists } from 'mastodon/actions/lists';
import ColumnLink from './column_link';
import { ListsIcon } from 'mastodon/utils/icons';

const getOrderedLists = createSelector(
  [(state) => state.get('lists')],
  (lists) => {
    if (!lists) {
      return lists;
    }

    return lists
      .toList()
      .filter((item) => !!item)
      .sort((a, b) => a.get('title').localeCompare(b.get('title')))
      .take(4);
  }
);

const mapStateToProps = (state) => ({
  lists: getOrderedLists(state),
});

export default
@withRouter
@connect(mapStateToProps)
class ListPanel extends ImmutablePureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    lists: ImmutablePropTypes.list,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchLists());
  }

  render() {
    const { lists } = this.props;

    if (!lists || lists.isEmpty()) {
      return null;
    }

    return (
      <div className='list-panel'>
        {lists.map((list) => (
          <ColumnLink
            icon={<span className='column-link__icon'>{ListsIcon}</span>}
            key={list.get('id')}
            strict
            text={list.get('title')}
            to={`/lists/${list.get('id')}`}
            transparent
          />
        ))}
      </div>
    );
  }
}
