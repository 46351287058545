import { connect } from 'react-redux';
import { toggleMegaBoost } from '../../../actions/compose';
import MegaBoostSwitch from '../components/mega_boost_switch';

const mapStateToProps = (state) => {
  return {
    mega_boost: state.getIn(['compose', 'mega_boost']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onToggle(value) {
    dispatch(toggleMegaBoost(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MegaBoostSwitch);
