import ready from '../ready';

export let assetHost = '';

ready(() => {
  const cdnHost = document.querySelector('meta[name=cdn-host]');
  if (cdnHost) {
    assetHost = cdnHost.content || '';
  }
});

export const MPX_ADDRESS = '0xBb2fd14Cb2544Cb095e6b0F63cB821ff794ed022';
export const MEGA_BOOST_ADDRESS = '0xAc6d869829515ba6A4e48c7e5710A3EE395e5833';
export const ETHER_SCAN = 'https://sepolia.etherscan.io/address/';
export const CARDANO_SCAN = 'https://preprod.cardanoscan.io/address/';
export const MPX_ASSET_ID =
  '00e8f979b7c76ffe442ad8c5ba87c782c08f89cee6045fa71b3993914d5058';
export const MPX_ASSET_POLICY_ID =
  '00e8f979b7c76ffe442ad8c5ba87c782c08f89cee6045fa71b399391';
export const MPX_ASSET_NAME = '4d5058';
export const LOVE_LACE_TO_SEND = 3000000;
export const SCRIPT_ADDRESS =
  'addr_test1wpzac0xwphu4xncv30ngltnc77a96ejslnkvj53tldtnqngrllgs3';
