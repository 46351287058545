import Web3 from 'web3';

export const FETCH_DECIMAL_SUCCESS = 'FETCH_DECIMAL_SUCCESS';

export const getDecimal =
  ({ library, mpxAbi }) =>
  (dispatch) => {
    if (!library) return;
    const web3 = new Web3(library.provider);

    const mpxContract = new web3.eth.Contract(
      mpxAbi,
      '0xBb2fd14Cb2544Cb095e6b0F63cB821ff794ed022'
    );
    mpxContract.methods
      .decimals()
      .call()
      .then((response) => {
        dispatch({
          type: FETCH_DECIMAL_SUCCESS,
          decimal: response,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
