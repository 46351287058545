import { FETCH_DECIMAL_SUCCESS } from "mastodon/actions/getDecimal";
import { Map as ImmutableMap } from 'immutable';

const initialState = ImmutableMap();

export default function get_decimal(state = initialState, action) {
  switch (action.type) {
    case FETCH_DECIMAL_SUCCESS:
      return state.set('decimal', action.decimal);
    default:
      return state;
  }
}
