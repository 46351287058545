import { connect } from 'react-redux';

import React from 'react';
import PropTypes from 'prop-types';
import { NotificationIcon } from 'mastodon/utils/icons';

const formatNumber = (num) => (num > 40 ? '40+' : num);

const NotificationIconWithBadge = ({ count }) => (
  <span className='column-link__icon icon-with-badge'>
    {NotificationIcon}
    {count > 0 && (
      <i className='icon-with-badge__badge'>{formatNumber(count)}</i>
    )}
  </span>
);

NotificationIconWithBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  count: state.getIn(['notifications', 'unread']),
});

export default connect(mapStateToProps)(NotificationIconWithBadge);
