import api from 'mastodon/api';
import { openModal } from './modal';

export const OPEN_MEGA_BOOST_MODAL = 'OPEN_MEGA_BOOST_MODAL';
export const CLOSE_MEGA_BOOST_MODAL = 'CLOSE_MEGA_BOOST_MODAL';
export const ACCOUNT_DAILY_STATS_FETCH_REQUEST =
  'ACCOUNT_DAILY_STATS_FETCH_REQUEST';
export const ACCOUNT_DAILY_STATS_FETCH_SUCCESS =
  'ACCOUNT_DAILY_STATS_FETCH_SUCCESS';
export const ACCOUNT_DAILY_STATS_FETCH_FAIL = 'ACCOUNT_DAILY_STATS_FETCH_FAIL';

export const initMegaBoost = (status) => (dispatch) =>
  dispatch(openModal('MEGABOOST', status));

export function fetchAccountDailyStats(accountId) {
  return (dispatch, getState) => {
    if (getState().getIn(['account_daily_stats', 'isLoading'])) {
      return;
    }

    dispatch(fetchAccountDailyStatsRequest());

    api(getState)
      .get(`/api/v1/accounts/${accountId}/account_daily_stats`)
      .then((response) => {
        dispatch(fetchAccountDailyStatsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAccountDailyStatsFail(error));
      });
  };
}

export function fetchAccountDailyStatsRequest() {
  return {
    type: ACCOUNT_DAILY_STATS_FETCH_REQUEST,
  };
}

export function fetchAccountDailyStatsSuccess(data) {
  return {
    type: ACCOUNT_DAILY_STATS_FETCH_SUCCESS,
    data,
  };
}

export function fetchAccountDailyStatsFail(error) {
  return {
    type: ACCOUNT_DAILY_STATS_FETCH_FAIL,
    error,
  };
}
