import Web3 from 'web3';

export const FETCH_MPX_BALANCE_SUCCESS = 'FETCH_MPX_BALANCE_SUCCESS';

export const getMPXBalance =
  ({ library, mpxAbi, account }) =>
  (dispatch) => {
    if (!library) return;
    const web3 = new Web3(library.provider);

    const mpxContract = new web3.eth.Contract(
      mpxAbi,
      '0xBb2fd14Cb2544Cb095e6b0F63cB821ff794ed022'
    );
    mpxContract.methods
      .balanceOf(account)
      .call()
      .then((response) => {
        dispatch({
          type: FETCH_MPX_BALANCE_SUCCESS,
          mpx_balance: response,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
