import React from 'react';
import IconButton from '../../../components/icon_button';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { MegaBoostIcon } from 'mastodon/utils/icons';
import Toggle from 'react-toggle';

const messages = defineMessages({
  mega_boost_off: {
    id: 'mega_boost_switch.off',
    defaultMessage: 'Mega-boost is off',
  },
  mega_boost_on: {
    id: 'mega_boost_switch.on',
    defaultMessage: 'Mega-boost is on',
  },
});

export default
@injectIntl
class MegaBoostSwitch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.megaBoostRef = React.createRef();
  }

  static propTypes = {
    unavailable: PropTypes.bool,
    active: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    mega_boost: PropTypes.bool,
  };

  state = {
    isOnMegaBoost: true,
    active: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.props.mega_boost !== undefined) {
      this.setState({ isOnMegaBoost: this.props.mega_boost });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.megaBoostRef &&
      !this.megaBoostRef.current.contains(event.target)
    ) {
      this.setState({ active: false });
    }
  };

  handleClick = () => {
    this.setState({ active: !this.state.active });
  };

  onChange = (value) => {
    this.setState({ isOnMegaBoost: value.target.checked });
    this.props.onToggle(value.target.checked);
  };

  render() {
    const { intl } = this.props;
    const { active, isOnMegaBoost } = this.state;

    return (
      <div className="compose-form__megaboost-button" ref={this.megaBoostRef}>
        <IconButton
          icon={MegaBoostIcon}
          title={'switch-icon'}
          onClick={this.handleClick}
          className={`compose-form__megaboost-button-icon ${
            isOnMegaBoost ? 'active' : ''
          }`}
          size={18}
          inverted
        />
        {active && (
          <div className="compose-form__megaboost-switch">
            <div className="setting-toggle">
              <Toggle onChange={this.onChange} checked={isOnMegaBoost} />
              <span className="setting-toggle__label">
                {intl.formatMessage(
                  isOnMegaBoost
                    ? messages.mega_boost_on
                    : messages.mega_boost_off
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
