export function EmojiPicker() {
  return import(
    /* webpackChunkName: "emoji_picker" */ '../../emoji/emoji_picker'
  );
}

export function Compose() {
  return import(/* webpackChunkName: "features/compose" */ '../../compose');
}

export function Notifications() {
  return import(
    /* webpackChunkName: "features/notifications" */ '../../notifications'
  );
}

export function BalanceHistoryContainer() {
  return import(
    /* webpackChunkName: "features/balance_history" */ '../../balance_history_container'
  );
}

export function HomeTimeline() {
  return import(
    /* webpackChunkName: "features/home_timeline" */ '../../home_timeline'
  );
}

export function PublicTimeline() {
  return import(
    /* webpackChunkName: "features/public_timeline" */ '../../public_timeline'
  );
}

export function CommunityTimeline() {
  return import(
    /* webpackChunkName: "features/community_timeline" */ '../../community_timeline'
  );
}

export function HashtagTimeline() {
  return import(
    /* webpackChunkName: "features/hashtag_timeline" */ '../../hashtag_timeline'
  );
}

export function DirectTimeline() {
  return import(
    /* webpackChunkName: "features/direct_timeline" */ '../../direct_timeline'
  );
}

export function ListTimeline() {
  return import(
    /* webpackChunkName: "features/list_timeline" */ '../../list_timeline'
  );
}

export function Lists() {
  return import(/* webpackChunkName: "features/lists" */ '../../lists');
}

export function Status() {
  return import(/* webpackChunkName: "features/status" */ '../../status');
}

export function GettingStarted() {
  return import(
    /* webpackChunkName: "features/getting_started" */ '../../getting_started'
  );
}

export function KeyboardShortcuts() {
  return import(
    /* webpackChunkName: "features/keyboard_shortcuts" */ '../../keyboard_shortcuts'
  );
}

export function PinnedStatuses() {
  return import(
    /* webpackChunkName: "features/pinned_statuses" */ '../../pinned_statuses'
  );
}

export function AccountTimeline() {
  return import(
    /* webpackChunkName: "features/account_timeline" */ '../../account_timeline'
  );
}

export function AccountGallery() {
  return import(
    /* webpackChunkName: "features/account_gallery" */ '../../account_gallery'
  );
}

export function Followers() {
  return import(/* webpackChunkName: "features/followers" */ '../../followers');
}

export function Following() {
  return import(/* webpackChunkName: "features/following" */ '../../following');
}

export function Reblogs() {
  return import(/* webpackChunkName: "features/reblogs" */ '../../reblogs');
}

export function Favourites() {
  return import(
    /* webpackChunkName: "features/favourites" */ '../../favourites'
  );
}

export function FollowRequests() {
  return import(
    /* webpackChunkName: "features/follow_requests" */ '../../follow_requests'
  );
}

export function GenericNotFound() {
  return import(
    /* webpackChunkName: "features/generic_not_found" */ '../../generic_not_found'
  );
}

export function FavouritedStatuses() {
  return import(
    /* webpackChunkName: "features/favourited_statuses" */ '../../favourited_statuses'
  );
}

export function FollowedTags() {
  return import(
    /* webpackChunkName: "features/followed_tags" */ '../../followed_tags'
  );
}

export function BookmarkedStatuses() {
  return import(
    /* webpackChunkName: "features/bookmarked_statuses" */ '../../bookmarked_statuses'
  );
}

export function Blocks() {
  return import(/* webpackChunkName: "features/blocks" */ '../../blocks');
}

export function DomainBlocks() {
  return import(
    /* webpackChunkName: "features/domain_blocks" */ '../../domain_blocks'
  );
}

export function Mutes() {
  return import(/* webpackChunkName: "features/mutes" */ '../../mutes');
}

export function MuteModal() {
  return import(
    /* webpackChunkName: "modals/mute_modal" */ '../components/mute_modal'
  );
}

export function BlockModal() {
  return import(
    /* webpackChunkName: "modals/block_modal" */ '../components/block_modal'
  );
}

export function ReportModal() {
  return import(
    /* webpackChunkName: "modals/report_modal" */ '../components/report_modal'
  );
}

export function MediaGallery() {
  return import(
    /* webpackChunkName: "status/media_gallery" */ '../../../components/media_gallery'
  );
}

export function Video() {
  return import(/* webpackChunkName: "features/video" */ '../../video');
}

export function EmbedModal() {
  return import(
    /* webpackChunkName: "modals/embed_modal" */ '../components/embed_modal'
  );
}

export function ListEditor() {
  return import(
    /* webpackChunkName: "features/list_editor" */ '../../list_editor'
  );
}

export function ListAdder() {
  return import(
    /*webpackChunkName: "features/list_adder" */ '../../list_adder'
  );
}

export function Tesseract() {
  return import(/*webpackChunkName: "tesseract" */ 'tesseract.js');
}

export function Audio() {
  return import(/* webpackChunkName: "features/audio" */ '../../audio');
}

export function Directory() {
  return import(/* webpackChunkName: "features/directory" */ '../../directory');
}

export function FollowRecommendations() {
  return import(
    /* webpackChunkName: "features/follow_recommendations" */ '../../follow_recommendations'
  );
}

export function CompareHistoryModal() {
  return import(
    /*webpackChunkName: "modals/compare_history_modal" */ '../components/compare_history_modal'
  );
}

export function Explore() {
  return import(/* webpackChunkName: "features/explore" */ '../../explore');
}

export function FilterModal() {
  return import(
    /*webpackChunkName: "modals/filter_modal" */ '../components/filter_modal'
  );
}

export function InteractionModal() {
  return import(
    /*webpackChunkName: "modals/interaction_modal" */ '../../interaction_modal'
  );
}

export function SubscribedLanguagesModal() {
  return import(
    /*webpackChunkName: "modals/subscribed_languages_modal" */ '../../subscribed_languages_modal'
  );
}

export function ClosedRegistrationsModal() {
  return import(
    /*webpackChunkName: "modals/closed_registrations_modal" */ '../../closed_registrations_modal'
  );
}

export function About() {
  return import(/*webpackChunkName: "features/about" */ '../../about');
}

export function PrivacyPolicy() {
  return import(
    /*webpackChunkName: "features/privacy_policy" */ '../../privacy_policy'
  );
}

export function MegaBoostModal() {
  return import(
    /* webpackChunkName: "modals/mega_boost_modal" */ '../components/mega_boost_modal'
  );
}

export function ConnectWalletModal() {
  return import(
    /* webpackChunkName: "modals/connect_wallet_modal" */ '../components/connect_wallet_modal'
  );
}

export function DisconnectWalletModal() {
  return import(
    /* webpackChunkName: "modals/disconnect_wallet_modal" */ '../components/disconnect_wallet_modal'
  );
}
