import { Map as ImmutableMap, fromJS } from 'immutable';
import {
  BALANCE_HISTORY_FETCH_FAIL,
  BALANCE_HISTORY_FETCH_SUCCESS,
} from 'mastodon/actions/balance_history';

const normalizeBlanceHistoryList = (state, balance_history_list) => {
  return state.set('balance_history', fromJS(balance_history_list));
};

const initialState = ImmutableMap({
  balance_history: ImmutableMap(),
});

export default function balance_history(state = initialState, action) {
  switch (action.type) {
    case BALANCE_HISTORY_FETCH_SUCCESS:
      return normalizeBlanceHistoryList(state, action.balance_history);
    case BALANCE_HISTORY_FETCH_FAIL:
      return state.set(action.id, false);
    default:
      return state;
  }
}
