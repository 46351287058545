import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { NonceProvider } from 'react-select';
import DateTimePicker from './DateTimePicker';
import { defineMessages, injectIntl } from 'react-intl';
import { convertToUnixTime } from 'mastodon/utils/format_date';

const optionsAction = [
  { value: '', label: 'All' },
  { value: 'claim', label: 'Claim' },
  { value: 'mega_boost', label: 'Mega-boost' },
];

const optionsNetwork = [
  { value: '', label: 'All' },
  { value: 'ether', label: 'Ethereum' },
  { value: 'cardano', label: 'Cardano' },
];

const messages = defineMessages({
  search_by_date: {
    id: 'balance_history.search_by_date',
    defaultMessage: 'Search by date',
  },
  action: { id: 'balance_history.action', defaultMessage: 'Action' },
  network: { id: 'balance_history.network', defaultMessage: 'Network' },
});

export default
@injectIntl
class FilterBalanceHistoryTable extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    onSelectAction: PropTypes.func,
    onSelectNetwork: PropTypes.func,
    handleSelectDate: PropTypes.func,
    actionType: PropTypes.string,
  };

  state = {
    selectedAction: '',
    selectedNetwork: '',
    selectedDate: null,
  };

  handleSelectChangeAction = (selectedAction) => {
    this.setState({ selectedAction });
    this.props.onSelectAction(selectedAction.value);
  };
  handleSelectChangeNetwork = (selectedNetwork) => {
    this.setState({ selectedNetwork });
    this.props.onSelectNetwork(selectedNetwork.value);
  };

  handleDateChange = (selectedDates) => {
    const formatedDate = selectedDates.map((date) => convertToUnixTime(date));
    this.props.handleSelectDate(formatedDate);
  };

  render() {
    const { intl } = this.props;

    return (
      <div className='search-wrapper'>
        <div className='select-filter'>
          <div>
            <p>{intl.formatMessage(messages.action)}</p>
            <NonceProvider
              nonce={document.querySelector('meta[name=style-nonce]').content}
              cacheKey='tags'
            >
              <Select
                options={optionsAction}
                value={this.state.selectedAction}
                onChange={this.handleSelectChangeAction}
                className='custom-select__container'
                classNamePrefix='custom-select'
                placeholder='Filter'
              />
            </NonceProvider>
          </div>
          <div>
            <p>{intl.formatMessage(messages.network)}</p>
            <NonceProvider
              nonce={document.querySelector('meta[name=style-nonce]').content}
              cacheKey='tags'
            >
              <Select
                options={optionsNetwork}
                value={this.state.selectedNetwork}
                onChange={this.handleSelectChangeNetwork}
                className='custom-select__container'
                classNamePrefix='custom-select'
                placeholder='Filter'
              />
            </NonceProvider>
          </div>
        </div>

        <div className='search-by-date'>
          <p>{intl.formatMessage(messages.search_by_date)}:</p>
          <DateTimePicker
            element='input'
            name='datetimepicker'
            onChange={this.handleDateChange}
          />
        </div>
      </div>
    );
  }
}
