import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { MPX_DECIMAL, convertNumber, formatNo } from 'mastodon/utils/numbers';

const messages = defineMessages({
  rank: {
    id: 'mega_boost_modal.rank',
    defaultMessage: 'Rank',
  },
  username: {
    id: 'mega_boost_modal.username',
    defaultMessage: 'Username',
  },
  total_mega_boosted: {
    id: 'mega_boost_modal.total_mega_boosted',
    defaultMessage: 'Total mega-boosted',
  },
  first_mega_boost_message: {
    id: 'mega_boost_modal.first_mega_boost_message',
    defaultMessage: 'You will be the first mega-boosted here!',
  },
});

export default
@injectIntl
class MegaBoostRank extends React.PureComponent {
  static propTypes = {
    megaBoostRankList: PropTypes.object,
    className: PropTypes.string,
    intl: PropTypes.object.isRequired,
    currentUserName: PropTypes.string,
  };

  render() {
    const { intl, className, megaBoostRankList } = this.props;

    return megaBoostRankList && megaBoostRankList.top_users?.length > 0 ? (
      <div className={`mega-boost-rank-table ${className}`}>
        <div className='rank-table'>
          <table className='accounts-table'>
            <thead>
              <tr>
                <td>{intl.formatMessage(messages.rank)}</td>
                <td>
                  <span>{intl.formatMessage(messages.username)} </span>
                </td>
                <td>
                  <span>{intl.formatMessage(messages.total_mega_boosted)}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              {megaBoostRankList?.top_users?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{formatNo(index + 1)}</td>
                    <td>
                      <span className='currency'>@{item.username}</span>
                    </td>
                    <td>
                      {item?.total_mpx
                        ? convertNumber(item.total_mpx / MPX_DECIMAL)
                        : 0}{' '}
                      MPX
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <table className='accounts-table current-user-position'>
          <tbody>
            <tr>
              <td>{formatNo(megaBoostRankList?.current_user_rank)}</td>
              <td>@{this.props.currentUserName}</td>
              <td>
                {megaBoostRankList?.current_user_total_mpx
                  ? convertNumber(
                      megaBoostRankList?.current_user_total_mpx / MPX_DECIMAL
                    )
                  : 0}{' '}
                MPX
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : (
      <p className='emty-message first-mega-boost-message'>
        {intl.formatMessage(messages.first_mega_boost_message)}
      </p>
    );
  }
}
