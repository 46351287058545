import React, { useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from './button';
import api from 'mastodon/api';
import { me } from 'mastodon/initial_state';
import { useDispatch, useSelector } from 'react-redux';
import MPXABI from 'mastodon/abis/MindplexUpgradeableToken.json';
import MegaBoostABI from 'mastodon/abis/MegaBoost.json';
import Web3 from 'web3';
import { loadingIcon } from 'mastodon/utils/icons';
import { closeModal } from 'mastodon/actions/modal';
import { MEGA_BOOST_ADDRESS, MPX_ADDRESS } from 'mastodon/utils/config';
import { MPX_DECIMAL } from 'mastodon/utils/numbers';
import {
  buildSendTokenToPlutusScript,
  useAppCardano,
} from 'mastodon/containers/cardano_context';
import { useWeb3React } from '@web3-react/core';
import { SUPPORTED_NETWORKS } from 'mastodon/features/ui/components/connect_wallet_modal';
import { showAlert, showAlertForError } from 'mastodon/actions/alerts';

const messages = defineMessages({
  are_you_sure: {
    id: 'mega_boost_modal.are_you_sure',
    defaultMessage: 'Are you sure',
  },
  to_mega_boost: {
    id: 'mega_boost_modal.to_mega_boost',
    defaultMessage: 'to mega-boost',
  },
  to_this_toot: {
    id: 'mega_boost_modal.to_this_toot',
    defaultMessage: ' to this toot?',
  },
  yes: {
    id: 'mega_boost_modal.yes',
    defaultMessage: 'Yes',
  },
  not_enough_approval: {
    id: 'mega_boost_modal.not_enough_approval',
    defaultMessage: 'Not enough approval',
  },
  smaller_than_0: {
    id: 'mega_boost_modal.smaller_than_0',
    defaultMessage: 'The mega-boosted should be greater than 0.',
  },
  refused_mega_boost: {
    id: 'mega_boost_modal.refused_mega_boost',
    defaultMessage: 'The toot refused to mega-boost!',
  },
  approving: {
    id: 'mega_boost_modal.approving',
    defaultMessage: 'Approving...',
  },
});

const ConfirmationMegaBoostModal = ({ statusId, amount, intl }) => {
  const { library, account, active } = useWeb3React();
  const { isEnabled, provider, currentWallet, usedAddresses, getUtxos } =
    useAppCardano();
  const [isApproving, setIsApproving] = useState(false);

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.getIn(['accounts', me]));

  function onClose() {
    dispatch(closeModal('CONFIRMATION_MEGABOOST'));
  }

  function onCloseMegaBoost() {
    dispatch(closeModal('MEGABOOST'));
  }

  function handleShowAlert(message) {
    dispatch(showAlert('', message));
  }

  function handleShowAlertForError(err) {
    dispatch(showAlertForError(err));
  }

  const getMPXAllowance = async () => {
    try {
      const mpxContract = getMPXContract(library);
      const allowance = await mpxContract.methods
        .allowance(account, MEGA_BOOST_ADDRESS)
        .call();

      return allowance;
    } catch (err) {
      handleShowAlertForError(err);
      throw new NetworkError();
    }
  };

  const approveMPX = async () => {
    try {
      const mpxContract = getMPXContract(library);

      return await mpxContract.methods
        .approve(MEGA_BOOST_ADDRESS, amount * MPX_DECIMAL)
        .send({
          from: account,
        });
    } catch (error) {
      if (error.code === 4001) {
        handleShowAlert('User denied approved');
        throw new ApproveRejected();
      }
      throw error;
    }
  };

  const getMegaBoostSignature = async (network) => {
    try {
      const params = {
        token_amount: amount * MPX_DECIMAL,
        status_id: statusId,
        network,
      };

      const accountId = userId.get('id');

      const response = await api().get(
        `/api/v1/accounts/${accountId}/mega_boost_signature`,
        {
          params,
        }
      );

      if (response && response.data) {
        return response.data;
      }

      return null;
    } catch (err) {
      throw new NetworkError();
    }
  };

  const tryMegaBoostInEthContract = async (signature) => {
    try {
      const web3 = new Web3(library.provider);
      const megaBoostContract = new web3.eth.Contract(
        MegaBoostABI,
        MEGA_BOOST_ADDRESS
      );

      const allowanceAmount = await getMPXAllowance();
      if (allowanceAmount < amount * MPX_DECIMAL) {
        await approveMPX();
        const allowanceAmount = await getMPXAllowance();
        if (allowanceAmount < amount * MPX_DECIMAL) {
          handleShowAlert('Not enough approval', '');
          return null;
        }
      }

      await megaBoostContract.methods
        .megaBoost(userId.get('id'), statusId, amount * MPX_DECIMAL, signature)
        .send({
          from: account,
        });
      handleShowAlert('Transaction complete');
    } catch (err) {
      if (err instanceof NetworkError || err instanceof ApproveRejected) {
        throw err;
      }
      if (err.code === 4001) {
        handleShowAlert('Transaction reject', '');
        throw new TransactionRejected();
      }
      throw new TransactionError();
    }
  };

  const tryMegaBoostInCardanoContract = async (stakeData) => {
    try {
      await buildSendTokenToPlutusScript({
        provider,
        stakeData,
        amount,
        currentWallet,
        currentAddress: usedAddresses[0],
      });
      handleShowAlert('Transaction complete');
      let count = 10;
      const intervalId = setInterval(() => {
        if (count > 0) {
          getUtxos();
          count--;
        } else {
          clearInterval(intervalId);
        }
      }, 10000);
    } catch (err) {
      if (err instanceof TransactionRejected) {
        handleShowAlert('Transaction reject', '');
        throw err;
      } else {
        throw new TransactionError();
      }
    }
  };

  const handleCardanoMegaboost = async () => {
    const data = await getMegaBoostSignature(SUPPORTED_NETWORKS.cardano);
    return await tryMegaBoostInCardanoContract(data?.stake_data?.data);
  };

  const handleEthMegaboost = async () => {
    const data = await getMegaBoostSignature(SUPPORTED_NETWORKS.ethereum);
    return await tryMegaBoostInEthContract(data?.data);
  };

  const onConfirm = async () => {
    try {
      setIsApproving(true);
      if (active) {
        return await handleEthMegaboost();
      }

      if (isEnabled) {
        return await handleCardanoMegaboost();
      }

      return null;
    } catch (err) {
      if (err instanceof NetworkError) {
        return handleShowAlertForError(err);
      }

      if (err instanceof TransactionError) {
        return handleShowAlert('Transaction error', '');
      }

      console.error(err);
    } finally {
      setIsApproving(false);
      onClose();
      onCloseMegaBoost();
    }
  };

  return (
    <div className='modal-root__modal verify-modal confirm-mega-boost-modal'>
      <div className='confirm-mega-boost-modal__message'>
        {isApproving ? (
          <div>
            <p className='loading-icon'>{loadingIcon}</p>
            <p>{intl.formatMessage(messages.approving)}</p>
          </div>
        ) : (
          <div>
            <p>{intl.formatMessage(messages.are_you_sure)}</p>
            <p>
              <span>
                {intl.formatMessage(messages.to_mega_boost)} {amount} MPX{' '}
                {intl.formatMessage(messages.to_this_toot)}{' '}
              </span>
            </p>
          </div>
        )}
      </div>

      <div className='modal-root__action-bar verify-modal__footer'>
        <Button
          onClick={onClose}
          className='verify-modal__footer-cancel'
          disabled={isApproving}
        >
          <FormattedMessage
            id='confirmation_modal.cancel'
            defaultMessage='Cancel'
          />
        </Button>
        <Button
          onClick={onConfirm}
          text={intl.formatMessage(messages.yes)}
          className='modal-root__cancel-button verify-modal__footer-confirm'
          disabled={isApproving}
        />
      </div>
    </div>
  );
};

ConfirmationMegaBoostModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCloseMegaBoost: PropTypes.func,
  intl: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  amount: PropTypes.number,
  statusId: PropTypes.string.isRequired,
};

export default injectIntl(ConfirmationMegaBoostModal);

function getMPXContract(library) {
  const web3 = new Web3(library.provider);

  return new web3.eth.Contract(MPXABI, MPX_ADDRESS);
}

class TransactionError extends Error {}
class NetworkError extends Error {}
class ApproveRejected extends Error {}
export class TransactionRejected extends Error {}
