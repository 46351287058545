import api from 'mastodon/api';

export const FETCH_CLAIMED_REWARED_SUCCESS = 'FETCH_CLAIMED_REWARED_SUCCESS';
export const FETCH_TOTAL_MPXR_SUCCESS = 'FETCH_TOTAL_MPXR_SUCCESS';

export const fetchClaimedAndRewarded =
  ({ accountId, currency }) =>
  (dispatch) => {
    api()
      .get(`/api/v1/accounts/${accountId}/show_claimed_rewarded`, {
        params: {
          currency,
        },
      })
      .then((response) => {
        dispatch(fetchClaimedAndRewardedSuccess(response?.data));
      })
      .catch((error) => {
        console.log(error)
      });
  };

export const getTotalMPXR = (accountId) => (dispatch) => {
  api()
    .get(`/api/v1/accounts/${accountId}/account_mpxr`)
    .then((response) => {
      dispatch(fetchTotalMPXRSuccess(response?.data?.mpxr));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchClaimedAndRewardedSuccess = (data) => {
  return {
    type: FETCH_CLAIMED_REWARED_SUCCESS,
    data,
  };
};

export const fetchTotalMPXRSuccess = (totalMPXR) => {
  return {
    type: FETCH_TOTAL_MPXR_SUCCESS,
    totalMPXR,
  };
};
