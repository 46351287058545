import React from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import DismissableBanner from './dismissable_banner';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { switchNetwork } from './on_chain';
import { useDispatch } from 'react-redux';

const messages = defineMessages({
  unsupported_network: {
    id: 'home.unsupported_network',
    defaultMessage:
      'Network is unsupported. Please switch to the appropriate Ethereum network.',
  },
});

const UnsupportedNetworkStatus = ({ intl, id }) => {
  const { error, library } = useWeb3React();
  const dispatch = useDispatch();

  if (error instanceof UnsupportedChainIdError) {
    return (
      <DismissableBanner id={id}>
        <div className='unsupported-network-banner'>
          {intl.formatMessage(messages.unsupported_network)}
          <button
            className='switch-network-button'
            onClick={() => switchNetwork(library, dispatch)}
          >
            Switch network
          </button>
        </div>
      </DismissableBanner>
    );
  }

  return null;
};

UnsupportedNetworkStatus.propTypes = {
  intl: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default injectIntl(UnsupportedNetworkStatus);
