import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'mastodon/utils/format_date';
import { defineMessages, injectIntl } from 'react-intl';
import { MPX_DECIMAL, convertNumber, formatNo } from 'mastodon/utils/numbers';
import { SortIcon } from '../sortIcon';
import { connect } from 'react-redux';
import EtherIcon from '../../../images/icons/etherIcon.svg';
import CardanoIcon from '../../../images/icons/cardanoIcon.svg';
import { ITEM_PER_PAGE } from 'mastodon/actions/balance_history';

const messages = defineMessages({
  time: {
    id: 'balance_history.time',
    defaultMessage: 'Time',
  },
  no: {
    id: 'balance_history.no',
    defaultMessage: 'No',
  },
  balance_change: {
    id: 'balance_history.balance_change',
    defaultMessage: 'Balance change',
  },
  action: { id: 'balance_history.action', defaultMessage: 'Action' },
  network: { id: 'balance_history.network', defaultMessage: 'Network' },
  status: { id: 'balance_history.status', defaultMessage: 'Status' },
  reward: { id: 'balance_history.reward', defaultMessage: 'Reward' },
  claim: { id: 'balance_history.claim', defaultMessage: 'Claim' },
  mega_boost: {
    id: 'balance_history.mega_boost',
    defaultMessage: 'Mega-boost',
  },
  no_record: {
    id: 'balance_history.no_record',
    defaultMessage: 'No history yet.',
  },
  nothing_here: {
    id: 'balance_history.nothing_here',
    defaultMessage: 'There is nothing here!',
  },
  succeed: {
    id: 'balance_history.succeed',
    defaultMessage: 'Succeed',
  },
  failed: {
    id: 'balance_history.failed',
    defaultMessage: 'Failed',
  },
  processing: {
    id: 'balance_history.processing',
    defaultMessage: 'Processing',
  },
});

export default
@injectIntl
@connect()
class BalanceTable extends React.PureComponent {
  static propTypes = {
    balanceHistoryList: PropTypes.object,
    intl: PropTypes.object.isRequired,
    handleSortTime: PropTypes.func,
    handleSortBalance: PropTypes.func,
    className: PropTypes.string,
    isFiltering: PropTypes.bool,
  };

  convertAction = (action) => {
    const { intl } = this.props;
    {
      switch (action) {
        case 'claim':
          return intl.formatMessage(messages.claim);
        case 'mega_boost':
          return intl.formatMessage(messages.mega_boost);
        default:
          return null;
      }
    }
  };

  convertStatus = (status) => {
    const { intl } = this.props;
    {
      switch (status) {
        case 'paid':
        case 'done':
        case null:
          return intl.formatMessage(messages.succeed);
        case 'processing':
          return intl.formatMessage(messages.processing);
        case 'failed':
          return intl.formatMessage(messages.failed);
        default:
          return status;
      }
    }
  };
  render() {
    const { balanceHistoryList, intl, handleSortTime, className, isFiltering } =
      this.props;
    const { balanceHistory, meta } = balanceHistoryList;

    return (
      <div className={`balance-history-table ${className}`}>
        <table className='accounts-table'>
          <thead>
            <tr>
              <td className='td-no'>{intl.formatMessage(messages.no)}</td>
              <td className='td-time'>
                <span className='sorted-title'>
                  <span>{intl.formatMessage(messages.time)} </span>
                  <SortIcon handleSort={handleSortTime} />
                </span>
              </td>
              <td className='td-balance'>
                <span>{intl.formatMessage(messages.balance_change)} </span>
              </td>
              <td className='td-action'>
                {intl.formatMessage(messages.action)}
              </td>
              <td className='text-center'>
                {intl.formatMessage(messages.network)}
              </td>
              <td className='text-right'>
                {intl.formatMessage(messages.status)}
              </td>
            </tr>
          </thead>
          <tbody>
            {balanceHistory?.length > 0 ? (
              balanceHistory.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {formatNo(
                        index + 1 + (+meta.current_page - 1) * ITEM_PER_PAGE
                      )}
                    </td>
                    <td>{formatDate(item.created_at)}</td>
                    <td>
                      {item?.balance
                        ? `${
                            item?.action === 'mega_boost' ? '-' : '+'
                          }\xa0${convertNumber(item.balance / MPX_DECIMAL)}`
                        : 0}
                      <span className='currency'>{item.currency}</span>
                    </td>
                    <td>{this.convertAction(item.action)}</td>
                    <td className='text-center'>
                      {
                        <img
                          width={24}
                          height={24}
                          src={
                            item.network === 'ether' ? EtherIcon : CardanoIcon
                          }
                          alt='network'
                        />
                      }
                    </td>
                    <td className='text-right'>
                      {this.convertStatus(item.status)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan='6'>
                  <p className='emty-message'>
                    {intl.formatMessage(
                      isFiltering ? messages.nothing_here : messages.no_record
                    )}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
