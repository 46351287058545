import React from 'react';
import Logo from 'mastodon/components/logo';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { registrationsOpen, me } from 'mastodon/initial_state';
import Avatar from 'mastodon/components/avatar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from 'mastodon/actions/modal';
import { convertNumber } from 'mastodon/utils/numbers';
import OnChain from 'mastodon/components/on_chain';

const messages = defineMessages({
  totalMPX: { id: 'navigation_bar.total_mpx', defaultMessage: 'Total MPX:' },
  totalMPXR: { id: 'navigation_bar.total_mpxr', defaultMessage: 'Total MPXR:' },
});

const Account = connect((state) => ({
  account: state.getIn(['accounts', me]),
}))(({ account }) => (
  <Link to={`/@${account.get('acct')}`} title={account.get('acct')}>
    <Avatar account={account} size={35} />
  </Link>
));

const mapDispatchToProps = (dispatch) => ({
  openClosedRegistrationsModal() {
    dispatch(openModal('CLOSED_REGISTRATIONS'));
  },
});

export default
@withRouter
@injectIntl
@connect(null, mapDispatchToProps)
class Header extends React.PureComponent {
  static contextTypes = {
    identity: PropTypes.object,
  };

  static propTypes = {
    openClosedRegistrationsModal: PropTypes.func,
    location: PropTypes.object,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { signedIn } = this.context.identity;
    const { location, openClosedRegistrationsModal, intl } = this.props;

    let content;

    if (signedIn) {
      content = (
        <>
          <OnChain signedIn={signedIn} intl={intl} />
          {location.pathname !== '/publish' && (
            <Link to='/publish' className='button publish-header'>
              <FormattedMessage
                id='compose_form.publish_form'
                defaultMessage='Publish'
              />
            </Link>
          )}
          <Account />
        </>
      );
    } else {
      let signupButton;

      if (registrationsOpen) {
        signupButton = (
          <a href='/auth/sign_up' className='button button-tertiary'>
            <FormattedMessage
              id='sign_in_banner.create_account'
              defaultMessage='Create account'
            />
          </a>
        );
      } else {
        signupButton = (
          <button
            className='button button-tertiary'
            onClick={openClosedRegistrationsModal}
          >
            <FormattedMessage
              id='sign_in_banner.create_account'
              defaultMessage='Create account'
            />
          </button>
        );
      }

      content = (
        <>
          <a href='/auth/sign_in' className='button'>
            <FormattedMessage
              id='sign_in_banner.sign_in'
              defaultMessage='Sign in'
            />
          </a>
          {signupButton}
        </>
      );
    }

    return (
      <div className='ui__header'>
        <Link to='/' className='ui__header__logo'>
          <Logo />
        </Link>

        <div className='ui__header__links'>{content}</div>
      </div>
    );
  }
}
