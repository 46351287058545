import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import PropTypes from 'prop-types';
import Button from './button';

export default
@injectIntl
class VerifiedModal extends ImmutablePureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    verified: PropTypes.bool,
    accountName: PropTypes.string,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    comment: '',
    errMessage: '',
  };

  handleChange = (val) => {
    this.setState({ comment: val.target.value });
  };

  onSaveChange = () => {
    if (this.state.comment.trim() === '') {
      this.setState({
        errMessage: 'This field is required.',
      });
    } else {
      this.props.onSave(this.state.comment);
    }
  };

  render() {
    const {
      onClose,
      intl: { formatMessage },
      accountName,
      verified,
    } = this.props;
    const { reason, errMessage } = this.state;

    return (
      <div className="modal-root__modal verify-modal">
        <div className="verify-modal__body simple_form">
          <div className="verify-modal__message">
            <p>
              Are you sure <br />
              {`to ${
                verified ? 'turn on' : 'turn off'
              } verified label for ${accountName}?`}
            </p>
            <p>
              Please leave a comment for your action
              <span className="required-icon">*</span>
            </p>
          </div>
          <textarea
            id="upload-modal__description"
            className="setting-text light verify-modal__textarea"
            value={reason}
            onChange={this.handleChange}
            autoFocus
            rows={4}
          />
          <p className="error-message">{errMessage}</p>
        </div>
        <div className="modal-root__action-bar verify-modal__footer">
          <Button onClick={onClose} className="verify-modal__footer-cancel">
            <FormattedMessage
              id="confirmation_modal.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            text={'Confirm'}
            onClick={this.onSaveChange}
            className="modal-root__cancel-button verify-modal__footer-confirm"
          />
        </div>
      </div>
    );
  }
}
