import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { injectIntl } from 'react-intl';
import NavigationPanel from './navigation_panel';
import { useAppCardano } from 'mastodon/containers/cardano_context';

const NavigationPanelContainer = () => {
  const { active } = useWeb3React();
  const { isEnabled } = useAppCardano();

  return <NavigationPanel isShowBalanceHistory={active || isEnabled} />;
};

export default injectIntl(NavigationPanelContainer);
