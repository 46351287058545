import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from './button';
import MegaBoostRank from './mega_boost_rank';
import OnChain from 'mastodon/components/on_chain';
import { useWeb3React } from '@web3-react/core';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { me } from 'mastodon/initial_state';
import api from 'mastodon/api';
import MPXABI from 'mastodon/abis/MindplexUpgradeableToken.json';
import {
  MPX_DECIMAL,
  addComma,
  convertNumber,
  removeComma,
  testNumber,
} from 'mastodon/utils/numbers';
import { closeModal, openModal } from 'mastodon/actions/modal';
import { getMPXBalance } from 'mastodon/actions/mpx_balance';
import { CloseIcon } from 'mastodon/utils/icons';
import { useAppCardano } from 'mastodon/containers/cardano_context';

const messages = defineMessages({
  mega_boost: {
    id: 'mega_boost_modal.mega_boost',
    defaultMessage: 'Mega-boost',
  },
  total_mega_boosted: {
    id: 'mega_boost_modal.total_mega_boosted',
    defaultMessage: 'Total mega-boosted',
  },
  max_mega_boost: {
    id: 'mega_boost_modal.max_mega_boost',
    defaultMessage: 'Max mega-boost',
  },
  your_mega_boost: {
    id: 'mega_boost_modal.your_mega_boost',
    defaultMessage: 'Your mega-boost',
  },
  remaining_mega_boost: {
    id: 'mega_boost_modal.remaining_mega_boost',
    defaultMessage: 'Remaining mega-boost',
  },
  amount: { id: 'mega_boost_modal.amount', defaultMessage: 'Amount' },
  balance: { id: 'mega_boost_modal.balance', defaultMessage: 'Balance' },
  max: { id: 'mega_boost_modal.max', defaultMessage: 'Max' },
  are_you_sure: {
    id: 'mega_boost_modal.are_you_sure',
    defaultMessage: 'Are you sure',
  },
  to_mega_boost: {
    id: 'mega_boost_modal.to_mega_boost',
    defaultMessage: 'to mega-boost',
  },
  to_this_toot: {
    id: 'mega_boost_modal.to_this_toot',
    defaultMessage: ' to this toot?',
  },
  yes: {
    id: 'mega_boost_modal.yes',
    defaultMessage: 'Yes',
  },
  connect_wallet_message: {
    id: 'mega_boost_modal.connect_wallet_message',
    defaultMessage: 'Please connect wallet to mega-boost this toot.',
  },
  invalid_amount: {
    id: 'mega_boost_modal.invalid_amount',
    defaultMessage: ' Invalid amount',
  },
  not_enough_balance: {
    id: 'mega_boost_modal.not_enough_balance',
    defaultMessage: 'Not enough balance',
  },
  not_enough_approval: {
    id: 'mega_boost_modal.not_enough_approval',
    defaultMessage: 'Not enough approval',
  },
  smaller_than_0: {
    id: 'mega_boost_modal.smaller_than_0',
    defaultMessage: 'The mega-boosted should be greater than 0.',
  },
  refused_mega_boost: {
    id: 'mega_boost_modal.refused_mega_boost',
    defaultMessage: 'The toot refused to mega-boost!',
  },
});

const MegaBoostModal = ({ intl, props: status }) => {
  const signedIn = useSelector((state) => !!state.getIn(['meta', 'me']));

  const [amount, setAmount] = useState('');
  const [accountDailyStats, setAccountDailyStats] = useState(null);
  const [megaBoostRankList, setMegaBoostRankList] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMegaBoost, setErrorMegaBoost] = useState('');
  const [disabledMegaboost, setDisabledMegaboost] = useState(true);

  const userAccount = useSelector((state) => state.getIn(['accounts', me]));
  const mpxBalance = useSelector((state) =>
    state.getIn(['mpx_balance', 'mpx_balance'])
  );

  const dispatch = useDispatch();
  const { library, account, active } = useWeb3React();
  const { isEnabled, cardanoMpxBalance } = useAppCardano();

  const mpxBalanceInNetwork = active
    ? mpxBalance && mpxBalance / MPX_DECIMAL
    : isEnabled
    ? cardanoMpxBalance * 1
    : 0;

  const fetchAccountDailyStats = () => {
    let accountId = userAccount.get('id');

    api()
      .get(`/api/v1/accounts/${accountId}/account_daily_stats`)
      .then((response) => {
        setAccountDailyStats(response?.data);
      })
      .catch((error) => {});
  };

  const fetchStatusBalanceHistory = () => {
    const statusId = status.get('id');
    api()
      .get(`/api/v1/statuses/${statusId}/balance_histories`)
      .then((response) => {
        setMegaBoostRankList(response?.data);
      })
      .catch((error) => {});
  };

  const onKeyDownAmount = (e) => {
    const value = e.target.value;

    if (
      e.keyCode === 8 ||
      e.keyCode === 46 ||
      e.keyCode === 37 ||
      e.keyCode === 39
    ) {
      return true;
    }

    if (value.includes('.') && value.split('.')[1].length >= 3) {
      e.preventDefault();
    }

    if (!testNumber(e.key) || (e.key === '.' && value.includes('.'))) {
      e.preventDefault();
    }
  };

  const onGetMPXBalance = (library, mpxAbi, account) => {
    dispatch(getMPXBalance({ library, mpxAbi, account }));
  };

  const onOpenConfirm = (amount, status) => {
    dispatch(
      openModal('CONFIRMATION_MEGABOOST', {
        statusId: status,
        amount,
        canCloseInOverlay: false,
      })
    );
  };

  const onClose = () => {
    dispatch(closeModal('MEGABOOST'));
  };

  useEffect(() => {
    fetchAccountDailyStats();
    fetchStatusBalanceHistory();
  }, []);

  useEffect(() => {
    onGetMPXBalance(library, MPXABI, account);
  }, [library, account]);

  const onEnterAmount = (e) => {
    const inputVal = e.target.value;
    const formattedValue = removeComma(inputVal);
    const numberWithCommas = addComma(formattedValue);

    setAmount(numberWithCommas);
    setDisabledMegaboost(true);

    if (Number(formattedValue) <= 0) {
      setErrorMessage(intl.formatMessage(messages.smaller_than_0));
    } else if (
      Number(formattedValue) >
      accountDailyStats?.mpx_remain / MPX_DECIMAL
    ) {
      setErrorMessage(intl.formatMessage(messages.invalid_amount));
    } else if (
      Number(formattedValue) > Number(mpxBalanceInNetwork) ||
      mpxBalanceInNetwork === undefined
    ) {
      setErrorMessage(intl.formatMessage(messages.not_enough_balance));
    } else {
      setErrorMessage('');
      setDisabledMegaboost(false);
    }
  };

  const onClickMax = () => {
    const mpxB = mpxBalanceInNetwork;
    const mpxRemain = accountDailyStats?.mpx_remain / MPX_DECIMAL;
    if (!accountDailyStats?.mpx_remain) {
      setAmount(mpxB);
      return;
    }
    setAmount(mpxB < mpxRemain ? mpxB : mpxRemain);
    setErrorMessage('');
    setDisabledMegaboost(false);
  };

  const handleConfirmMetaboost = () => {
    if (accountDailyStats?.mega_boost_remain <= 0) {
      setErrorMegaBoost(
        `You got ${accountDailyStats?.app_config?.max_mega_boost_toots}/${accountDailyStats?.app_config?.max_mega_boost_toots} mega-boosted toots today. Try again tomorrow.`
      );
      return;
    }
    onOpenConfirm(Number(removeComma(`${amount}`)), status.get('id'));
  };

  const isMegaBoost = status.get('mega_boost');

  return (
    <div className='modal-root__modal mega-boost-modal'>
      <div className='close-icon' onClick={onClose}>
        {CloseIcon}
      </div>
      <p className='mega-boost-modal__title'>
        {intl.formatMessage(messages.mega_boost)}
      </p>
      <div>
        <div className='mega-boost-modal__body simple_form'>
          <div className='mega-boost-info'>
            <div>
              <div className='mega-boost-info__total-title'>
                <p className='list-dot' />
                <p>{intl.formatMessage(messages.total_mega_boosted)}</p>
              </div>
              <p className='mega-boost-info__value'>
                {megaBoostRankList?.status_total_mpx
                  ? convertNumber(
                      megaBoostRankList?.status_total_mpx / MPX_DECIMAL
                    )
                  : 0}
              </p>
            </div>
            {(isEnabled || active) && (
              <>
                <div>
                  <div className='mega-boost-info__title'>
                    <p className='list-dot' />
                    <p>{intl.formatMessage(messages.max_mega_boost)}</p>
                  </div>
                  <p className='mega-boost-info__value'>
                    {accountDailyStats
                      ? convertNumber(
                          accountDailyStats?.app_config?.max_limit_mpx
                        )
                      : 0}
                  </p>
                </div>
                <div>
                  <div className='mega-boost-info__title'>
                    <p className='list-dot' />
                    <p>{intl.formatMessage(messages.remaining_mega_boost)}</p>
                  </div>
                  <p className='mega-boost-info__value'>
                    {accountDailyStats?.mpx_remain
                      ? convertNumber(
                          accountDailyStats?.mpx_remain / MPX_DECIMAL
                        )
                      : 0}
                  </p>
                </div>
                <div>
                  <div className='mega-boost-info__title'>
                    <p className='list-dot' />
                    <p>{intl.formatMessage(messages.your_mega_boost)}</p>
                  </div>
                  <p className='mega-boost-info__value'>
                    {accountDailyStats?.mpx_spent
                      ? convertNumber(
                          accountDailyStats?.mpx_spent / MPX_DECIMAL
                        )
                      : 0}
                  </p>
                </div>
              </>
            )}
          </div>
          {(active || isEnabled) && isMegaBoost && (
            <div>
              <div className='amount-title'>
                <span>{intl.formatMessage(messages.amount)}</span>
                <span>
                  {intl.formatMessage(messages.balance)}:{' '}
                  {mpxBalanceInNetwork ? convertNumber(mpxBalanceInNetwork) : 0}{' '}
                  MPX
                </span>
              </div>
              <div className='amount-input'>
                <input
                  type='text'
                  name='amount'
                  placeholder='0'
                  value={amount}
                  onChange={onEnterAmount}
                  onKeyDown={onKeyDownAmount}
                />
                <Button className='max-btn' onClick={onClickMax}>
                  {intl.formatMessage(messages.max)}
                </Button>
                <p className='error-message'>{errorMessage}</p>
              </div>
            </div>
          )}
          {!isMegaBoost && (
            <p className='mega-boost-modal__refused-mega-boost'>
              {intl.formatMessage(messages.refused_mega_boost)}
            </p>
          )}
        </div>
        {active || isEnabled ? (
          isMegaBoost && (
            <div>
              <div className='modal-root__action-bar verify-modal__footer'>
                <Button
                  onClick={handleConfirmMetaboost}
                  className='mega-boost-modal__footer-button'
                  disabled={disabledMegaboost}
                >
                  <FormattedMessage
                    id='mega_boost_modal.mega_boost'
                    defaultMessage='Mega-boost'
                  />
                </Button>
              </div>
              <p className='error-message error-megaboost'>{errorMegaBoost}</p>
            </div>
          )
        ) : (
          <div className='connect-wallet-required'>
            <p>{intl.formatMessage(messages.connect_wallet_message)}</p>
            <OnChain
              signedIn={signedIn}
              // disabledAccountId={disabledAccountId}
            />
          </div>
        )}
      </div>
      <MegaBoostRank
        megaBoostRankList={megaBoostRankList}
        currentUserName={userAccount?.get('username')}
      />
    </div>
  );
  // }
};

MegaBoostModal.propTypes = {
  intl: PropTypes.object.isRequired,
  props: ImmutablePropTypes.map,
};

export default injectIntl(MegaBoostModal);
