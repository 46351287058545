import { Map as ImmutableMap } from 'immutable';
import {
  FETCH_CLAIMED_REWARED_SUCCESS,
  FETCH_TOTAL_MPXR_SUCCESS,
} from 'mastodon/actions/getClaimInfo';

const initialState = ImmutableMap();

export default function claimInfo(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLAIMED_REWARED_SUCCESS:
      return state.set('claimRewarded', action.data);
    case FETCH_TOTAL_MPXR_SUCCESS:
      return state.set('totalMPXR', action.totalMPXR);
    default:
      return state;
  }
}
