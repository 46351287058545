import dayjs from 'dayjs';

export const formatDate = (date) => {
  const newDate = new Date(date);

  return newDate.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

export const formatDateNumber = (date) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return date
    .toLocaleString('en-US', options)
    .replace(/\//g, '-')
    .replace(',', '');
};

export const convertToUnixTime = (date) => {
  return dayjs(date).unix();
};
