import {
  NetworkType,
  useCardano,
} from '@cardano-foundation/cardano-connect-with-wallet';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TransactionRejected } from 'mastodon/components/confirmation_mega_boost_modal';
import {
  Address,
  MultiAsset,
  Assets,
  ScriptHash,
  AssetName,
  TransactionUnspentOutput,
  TransactionUnspentOutputs,
  TransactionBuilder,
  TransactionBuilderConfigBuilder,
  TransactionOutputBuilder,
  LinearFee,
  BigNum,
  TransactionWitnessSet,
  Transaction,
  PlutusData,
  hash_plutus_data,
  TransactionOutput,
  Value,
} from '@emurgo/cardano-serialization-lib-asmjs';
import { Buffer } from 'buffer';
import { SUPPORTED_WALLETS } from 'mastodon/features/ui/components/connect_wallet_modal';
import {
  LOVE_LACE_TO_SEND,
  MPX_ASSET_ID,
  MPX_ASSET_NAME,
  MPX_ASSET_POLICY_ID,
  SCRIPT_ADDRESS,
} from 'mastodon/utils/config';

export const NAMI_REJECT_ERROR_CODE = 2;
const CARDANO_DECIMAL = 1e6;
export const cardanoContext = React.createContext();

export const CardanoProvider = ({ children }) => {
  const cardano = useCardano({ limitNetwork: NetworkType.MAINNET });
  const [currentWallet, setCurrentWallet] = useState('');

  const [provider, setProvider] = useState();

  const connectProvider = async (walletName, onConnect, onError) => {
    setCurrentWallet(walletName);
    await cardano.connect(walletName, onConnect, onError);
    setTimeout(async () => {
      if (walletName === SUPPORTED_WALLETS.nami.connectName) {
        setProvider(await window.cardano[walletName].enable());
      } else if (walletName === SUPPORTED_WALLETS.typhon.connectName) {
        setProvider(window.cardano[walletName]);
      }
    }, 200);
  };

  const disconnectProvider = () => {
    setProvider(undefined);
    return cardano.disconnect();
  };

  const [mpxBalance, setMpxBalance] = useState();
  const [utxos, setUtxos] = useState();

  const getUtxos = async () => {
    let Utxos = [];
    try {
      const rawUtxos = await window.cardano.getUtxos();

      for (const rawUtxo of rawUtxos) {
        const utxo = TransactionUnspentOutput.from_bytes(
          Buffer.from(rawUtxo, 'hex')
        );
        const input = utxo.input();
        const txid = Buffer.from(
          input.transaction_id().to_bytes(),
          'utf8'
        ).toString('hex');
        const txindx = input.index();
        const output = utxo.output();
        const amount = output.amount().coin().to_str(); // ADA amount in lovelace
        const multiasset = output.amount().multiasset();
        let multiAssetStr = '';

        if (multiasset) {
          const keys = multiasset.keys(); // policy Ids of thee multiasset
          const N = keys.len();
          // console.log(`${N} Multiassets in the UTXO`)

          for (let i = 0; i < N; i++) {
            const policyId = keys.get(i);
            const policyIdHex = Buffer.from(
              policyId.to_bytes(),
              'utf8'
            ).toString('hex');
            // console.log(`policyId: ${policyIdHex}`)
            const assets = multiasset.get(policyId);
            const assetNames = assets.keys();
            const K = assetNames.len();
            // console.log(`${K} Assets in the Multiasset`)

            for (let j = 0; j < K; j++) {
              const assetName = assetNames.get(j);
              const assetNameString = Buffer.from(
                assetName.name(),
                'utf8'
              ).toString();
              const assetNameHex = Buffer.from(
                assetName.name(),
                'utf8'
              ).toString('hex');
              const multiassetAmt = multiasset.get_asset(policyId, assetName);
              multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`;
              // console.log(assetNameString)
              // console.log(`Asset Name: ${assetNameHex}`)
            }
          }
        }

        const obj = {
          txid: txid,
          txindx: txindx,
          amount: amount,
          str: `${txid} #${txindx} = ${amount}`,
          multiAssetStr: multiAssetStr,
          TransactionUnspentOutput: utxo,
        };
        Utxos.push(obj);
        // console.log(`utxo: ${str}`)
      }

      setUtxos(Utxos);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (cardano.isEnabled) {
      getUtxos();
    }
  }, [cardano.isEnabled, currentWallet, cardano.usedAddresses]);

  const cardanoMpxBalance =
    utxos?.length &&
    utxos
      .filter((utxo) => {
        if (!utxo.multiAssetStr) return false;
        const [assetPolicyId, assetName] = utxo.multiAssetStr
          .split('+')[2]
          .trim()
          .split(' ')[0]
          .split('.');

        return (
          assetPolicyId === MPX_ASSET_POLICY_ID && assetName === MPX_ASSET_NAME
        );
      })?.[0]
      .multiAssetStr.split('+')[1]
      .trim();

  const getCardanoMpxBalance = async () => {
    try {
      switch (currentWallet) {
        case SUPPORTED_WALLETS.nami.connectName:
          setMpxBalance(cardanoMpxBalance);
          return;
        case SUPPORTED_WALLETS.typhon.connectName:
          if (provider) {
            const balance = await provider.getBalance();
            const mpxToken = balance.data?.tokens?.filter(
              (token) => token.assetId === MPX_ASSET_ID
            )?.[0];
            if (mpxToken) {
              setMpxBalance(mpxToken.amount);
            }
          }
          return;
        default:
          return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (cardano.isEnabled) {
      getCardanoMpxBalance();
    }
  }, [
    provider,
    cardano.isEnabled,
    cardano.usedAddresses,
    utxos,
    currentWallet,
  ]);

  return (
    <cardanoContext.Provider
      value={{
        ...cardano,
        cardanoMpxBalance: mpxBalance,
        connect: connectProvider,
        disconnect: disconnectProvider,
        provider,
        getCardanoMpxBalance,
        currentWallet,
        getUtxos,
      }}
    >
      {children}
    </cardanoContext.Provider>
  );
};

CardanoProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useAppCardano = () => useContext(cardanoContext);

const protocolParams = {
  linearFee: {
    minFeeA: '44',
    minFeeB: '155381',
  },
  minUtxo: '34482',
  poolDeposit: '500000000',
  keyDeposit: '2000000',
  maxValSize: 4000,
  maxTxSize: 8000,
  priceMem: 0.0577,
  priceStep: 0.0000721,
  coinsPerUtxoWord: '34482',
};

const linearFeeA = BigNum.from_str('44');
const linearFeeB = BigNum.from_str('155381');
const BigAssetName = Buffer.from('4d5058', 'hex');

async function initTransactionBuilder() {
  const linearFee = LinearFee.new(linearFeeA, linearFeeB);

  try {
    const txBuilder = TransactionBuilder.new(
      TransactionBuilderConfigBuilder.new()
        .fee_algo(linearFee)
        .pool_deposit(BigNum.from_str(protocolParams.poolDeposit))
        .key_deposit(BigNum.from_str(protocolParams.keyDeposit))
        .max_value_size(protocolParams.maxValSize)
        .max_tx_size(protocolParams.maxTxSize)
        .coins_per_utxo_word(BigNum.from_str(protocolParams.coinsPerUtxoWord))
        .build()
    );

    return txBuilder;
  } catch (error) {
    console.error('Init transaction error', error);
  }
}

const getTxUnspentOutputs = async (utxos) => {
  let txOutputs = TransactionUnspentOutputs.new();
  for (const utxo of utxos) {
    txOutputs.add(utxo.TransactionUnspentOutput);
  }
  return txOutputs;
};

const buildSendTokenToPlutusScriptTyphon = async ({
  provider,
  stakeData,
  amount,
}) => {
  const { error, reason } = await provider.paymentTransaction({
    outputs: [
      {
        address: SCRIPT_ADDRESS,
        tokens: [
          {
            assetName: '4d5058',
            policyId: MPX_ASSET_POLICY_ID,
            amount: `${amount * CARDANO_DECIMAL}`,
          },
        ],
        plutusDataCbor: stakeData,
      },
    ],
  });

  if (error) {
    if (reason === 'REJECT') {
      throw new TransactionRejected();
    }

    throw error;
  }
};

const buildSendTokenToPlutusScriptNami = async ({
  provider,
  amount,
  stakeData,
  currentAddress,
}) => {
  try {
    const txBuilder = await initTransactionBuilder();
    const ScriptAddress = Address.from_bech32(SCRIPT_ADDRESS);
    const shelleyChangeAddress = Address.from_bech32(currentAddress);

    let txOutputBuilder = TransactionOutputBuilder.new();
    txOutputBuilder = txOutputBuilder.with_address(ScriptAddress);

    const plutusData = PlutusData.from_bytes(Buffer.from(stakeData, 'hex'));
    const dataHash = hash_plutus_data(plutusData);
    txOutputBuilder = txOutputBuilder.with_data_hash(dataHash);
    txOutputBuilder = txOutputBuilder.with_plutus_data(plutusData);

    txOutputBuilder = txOutputBuilder.next();

    let multiAsset = MultiAsset.new();
    let assets = Assets.new();
    assets.insert(
      AssetName.new(BigAssetName), // Asset Name
      BigNum.from_str(`${amount * CARDANO_DECIMAL}`) // How much to send
    );
    multiAsset.insert(
      ScriptHash.from_bytes(Buffer.from(MPX_ASSET_POLICY_ID, 'hex')), // PolicyID
      assets
    );

    // txOutputBuilder = txOutputBuilder.with_asset_and_min_required_coin(multiAsset, BigNum.from_str(this.protocolParams.coinsPerUtxoWord))

    txOutputBuilder = txOutputBuilder.with_coin_and_asset(
      BigNum.from_str(LOVE_LACE_TO_SEND.toString()),
      multiAsset
    );

    const txOutput = txOutputBuilder.build();

    txBuilder.add_output(txOutput);

    // Find the available UTXOs in the wallet and
    // us them as Inputs
    const utxos = await getUtxos();
    const txUnspentOutputs = await getTxUnspentOutputs(utxos);

    txBuilder.add_inputs_from(txUnspentOutputs, 2);

    // calculate the min fee required and send any change to an address
    txBuilder.add_change_if_needed(shelleyChangeAddress);

    // once the transaction is ready, we build it to get the tx body without witnesses
    const txBody = txBuilder.build();

    // Tx witness
    const transactionWitnessSet = TransactionWitnessSet.new();

    const tx = Transaction.new(
      txBody,
      TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes())
    );

    let txVkeyWitnesses = await provider.signTx(
      Buffer.from(tx.to_bytes(), 'utf8').toString('hex'),
      true
    );
    txVkeyWitnesses = TransactionWitnessSet.from_bytes(
      Buffer.from(txVkeyWitnesses, 'hex')
    );

    transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

    const signedTx = Transaction.new(tx.body(), transactionWitnessSet);

    const submittedTxHash = await provider.submitTx(
      Buffer.from(signedTx.to_bytes(), 'utf8').toString('hex')
    );

    return {
      submittedTxHash: submittedTxHash,
      transactionIdLocked: submittedTxHash,
      lovelaceLocked: LOVE_LACE_TO_SEND,
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const buildSendTokenToPlutusScript = async ({
  provider,
  stakeData,
  amount,
  currentWallet,
  currentAddress,
}) => {
  switch (currentWallet) {
    case SUPPORTED_WALLETS.nami.connectName:
      await buildSendTokenToPlutusScriptNami({
        provider,
        amount,
        stakeData,
        currentAddress,
      });
      return;
    case SUPPORTED_WALLETS.typhon.connectName:
      await buildSendTokenToPlutusScriptTyphon({ provider, stakeData, amount });
      return;
    default:
      return;
  }
};

export const buildSendADATransaction = async (
  provider,
  currentWallet,
  adminAddress,
  userAddress,
  claimFee
) => {
  switch (currentWallet) {
    case SUPPORTED_WALLETS.nami.connectName:
      return await sendClaimFeeEmurgo({
        provider,
        adminAddress,
        userAddress,
        claimFee,
      });
    case SUPPORTED_WALLETS.typhon.connectName:
      return await sendClaimFeeTyphon({ provider, adminAddress, claimFee });
    default:
      throw new Error();
  }
};

async function sendClaimFeeEmurgo({
  provider,
  adminAddress,
  userAddress,
  claimFee,
}) {
  const txBuilder = await initTransactionBuilder();
  const shelleyOutputAddress = Address.from_bech32(adminAddress);
  const shelleyChangeAddress = Address.from_bech32(userAddress);

  txBuilder.add_output(
    TransactionOutput.new(
      shelleyOutputAddress,
      Value.new(BigNum.from_str(`${claimFee * CARDANO_DECIMAL}`))
    )
  );

  // Find the available UTXOs in the wallet and
  // us them as Inputs
  const utxos = await getUtxos();
  const txUnspentOutputs = await getTxUnspentOutputs(utxos);
  txBuilder.add_inputs_from(txUnspentOutputs, 1);

  // calculate the min fee required and send any change to an address
  txBuilder.add_change_if_needed(shelleyChangeAddress);

  // once the transaction is ready, we build it to get the tx body without witnesses
  const txBody = txBuilder.build();

  // Tx witness
  const transactionWitnessSet = TransactionWitnessSet.new();

  const tx = Transaction.new(
    txBody,
    TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes())
  );

  let txVkeyWitnesses = await provider.signTx(
    Buffer.from(tx.to_bytes(), 'utf8').toString('hex'),
    true
  );

  txVkeyWitnesses = TransactionWitnessSet.from_bytes(
    Buffer.from(txVkeyWitnesses, 'hex')
  );

  transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());

  const signedTx = Transaction.new(tx.body(), transactionWitnessSet);

  const submittedTxHash = await provider.submitTx(
    Buffer.from(signedTx.to_bytes(), 'utf8').toString('hex')
  );
  return submittedTxHash;
}

async function sendClaimFeeTyphon({ provider, adminAddress, claimFee }) {
  const { error, reason, data } = await provider.paymentTransaction({
    outputs: [
      {
        address: adminAddress,
        amount: `${claimFee * CARDANO_DECIMAL}`,
      },
    ],
  });

  if (error) {
    if (reason === 'REJECT') {
      throw new TransactionRejected();
    }
    throw error;
  }

  return data.transactionId;
}

const getUtxos = async () => {
  let Utxos = [];

  try {
    const rawUtxos = await window.cardano.getUtxos();
    for (const rawUtxo of rawUtxos) {
      const utxo = TransactionUnspentOutput.from_bytes(
        Buffer.from(rawUtxo, 'hex')
      );
      const input = utxo.input();
      const txid = Buffer.from(
        input.transaction_id().to_bytes(),
        'utf8'
      ).toString('hex');
      const txindx = input.index();
      const output = utxo.output();
      const amount = output.amount().coin().to_str(); // ADA amount in lovelace
      const multiasset = output.amount().multiasset();
      let multiAssetStr = '';

      if (multiasset) {
        const keys = multiasset.keys(); // policy Ids of thee multiasset
        const N = keys.len();
        // console.log(`${N} Multiassets in the UTXO`)

        for (let i = 0; i < N; i++) {
          const policyId = keys.get(i);
          const policyIdHex = Buffer.from(policyId.to_bytes(), 'utf8').toString(
            'hex'
          );
          // console.log(`policyId: ${policyIdHex}`)
          const assets = multiasset.get(policyId);
          const assetNames = assets.keys();
          const K = assetNames.len();
          // console.log(`${K} Assets in the Multiasset`)

          for (let j = 0; j < K; j++) {
            const assetName = assetNames.get(j);
            const assetNameString = Buffer.from(
              assetName.name(),
              'utf8'
            ).toString();
            const assetNameHex = Buffer.from(assetName.name(), 'utf8').toString(
              'hex'
            );
            const multiassetAmt = multiasset.get_asset(policyId, assetName);
            multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`;
            // console.log(assetNameString)
            // console.log(`Asset Name: ${assetNameHex}`)
          }
        }
      }

      const obj = {
        txid: txid,
        txindx: txindx,
        amount: amount,
        str: `${txid} #${txindx} = ${amount}`,
        multiAssetStr: multiAssetStr,
        TransactionUnspentOutput: utxo,
      };
      Utxos.push(obj);
    }

    return Utxos;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
