import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const SORT_TYPE = {
  TIME: 'time',
  BALANCE: 'balance',
};

export class SortIcon extends ImmutablePureComponent {
  static propTypes = {
    handleSort: PropTypes.func,
  };

  state = {
    isOrder: '',
  };

  handleSortAsc = () => {
    this.props.handleSort(ORDER.ASC);
    this.setState({ isOrder: ORDER.ASC });
  };

  handleSort = () => {
    this.setState(
      { isOrder: this.state.isOrder === ORDER.ASC ? ORDER.DESC : ORDER.ASC },
      () => {
        this.props.handleSort(this.state.isOrder);
      }
    );
  };

  render() {
    return (
      <span className='sort-icon' onClick={this.handleSort}>
        <i
          className={`fa fa-sort-asc ${
            this.state.isOrder === ORDER.ASC ? 'active' : ''
          }`}
          aria-hidden='true'
        />
        <i
          className={`fa fa-sort-desc ${
            this.state.isOrder === ORDER.DESC ? 'active' : ''
          }`}
          aria-hidden='true'
        />
      </span>
    );
  }
}
