import { MPX_DECIMAL, convertNumber } from 'mastodon/utils/numbers';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClockIcon, OutlineWallet } from 'mastodon/utils/icons';
import { me } from 'mastodon/initial_state';
import Button from './button';
import { NavLink } from 'react-router-dom';
import { openModal } from 'mastodon/actions/modal';
import { useWeb3React } from '@web3-react/core';
import {
  fetchClaimedAndRewarded,
  getTotalMPXR,
} from 'mastodon/actions/getClaimInfo';
import CardanoIcon from '../../images/icons/cardanoIcon.svg';
import EtherIcon from '../../images/icons/etherIcon.svg';
import { useAppCardano } from 'mastodon/containers/cardano_context';

const messages = defineMessages({
  totalMPX: { id: 'reward.total_mpx', defaultMessage: 'Total MPX:' },
  totalMPXR: { id: 'reward.total_mpxr', defaultMessage: 'Total MPXR:' },
  balance: {
    id: 'reward.balance',
    defaultMessage: 'Balance',
  },
  claimable: {
    id: 'reward.claimable',
    defaultMessage: 'Claimable',
  },
  claimableAmount: {
    id: 'reward.claimable_amount',
    defaultMessage: 'Claimable amount',
  },
  claim: {
    id: 'reward.claim',
    defaultMessage: 'Claim',
  },
});

function ClaimReward({ signedIn, intl, classNames }) {
  const { isEnabled, cardanoMpxBalance } = useAppCardano();
  const dispatch = useDispatch();
  const balance = useSelector((state) =>
    state.getIn(['mpx_balance', 'mpx_balance'])
  );

  const userAccount = useSelector((state) => state.getIn(['accounts', me]));
  const accountId = userAccount?.get('id');
  const { active } = useWeb3React();
  const [claimable, setClaimable] = useState(0);

  const [currentNetwork, setCurrentNetwork] = useState('');
  const claimInfo = useSelector((state) =>
    state.getIn(['claimInfo', 'claimRewarded'])
  );
  const totalMPXR = useSelector((state) =>
    state.getIn(['claimInfo', 'totalMPXR'])
  );

  useEffect(() => {
    if (active) {
      setCurrentNetwork('ether');
    } else if (isEnabled) {
      setCurrentNetwork('cardano');
    }
  }, [active, isEnabled]);

  useEffect(() => {
    if (accountId) {
      dispatch(
        fetchClaimedAndRewarded({
          accountId,
          currency: 'mpx',
        })
      );
      dispatch(getTotalMPXR(accountId));
    }
  }, []);

  useEffect(() => {
    if (claimInfo?.length > 0) {
      claimInfo.forEach((info) => {
        info.network === currentNetwork && setClaimable(info.claimable);
      });
    }
  }, [claimInfo, currentNetwork]);

  const onClaim = () => {
    dispatch(
      openModal('CONFIRMATION_CLAIM_REWARD', {
        userAccount,
        claimable,
        canCloseInOverlay: false,
      })
    );
  };

  return (
    <div className={`balance-reward ${classNames}`}>
      <div className='balance-reward__title'>
        <div>
          {OutlineWallet}
          <span>{intl.formatMessage(messages.balance)}</span>
        </div>
        <NavLink to='/balance-history'>
          <span>{ClockIcon}</span>
        </NavLink>
      </div>
      <div className='balance-wrapper balance-navigation'>
        <p>
          {intl.formatMessage(messages.totalMPX)}{' '}
          {signedIn
            ? active
              ? convertNumber(balance / MPX_DECIMAL)
              : isEnabled
              ? convertNumber(cardanoMpxBalance * 1)
              : '-'
            : '-'}
        </p>
        <p>
          {intl.formatMessage(messages.totalMPXR)}{' '}
          {signedIn ? (totalMPXR ? convertNumber(totalMPXR) : 0) : '-'}
        </p>
      </div>
      {signedIn && (
        <div className='reward'>
          <p>{intl.formatMessage(messages.claimableAmount)}</p>
          <div className='claim-area'>
            <div className='claimable-amount'>
              <img
                width={20}
                height={20}
                src={currentNetwork === 'cardano' ? CardanoIcon : EtherIcon}
                alt='network'
              />
              <span>
                {claimable ? convertNumber(claimable / MPX_DECIMAL) : 0} MPX
              </span>
            </div>
            <Button disabled={!claimable} onClick={onClaim}>
              {intl.formatMessage(messages.claim)}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

ClaimReward.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  classNames: PropTypes.string,
};

export default injectIntl(ClaimReward);
