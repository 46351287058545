import React from 'react';
import PropTypes from 'prop-types';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { CalenderIcon } from 'mastodon/utils/icons';

export default class DateTimePicker extends React.PureComponent {
  static propTypes = {
    element: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.dateRangeRef = React.createRef();
  }

  componentDidMount() {
    flatpickr(this.dateRangeRef.current, {
      mode: 'range',
      wrap: false,
      dateFormat: 'M d, Y, H:i',
      enableTime: true,
      maxDate: new Date(9999, 12, 31),
      maxYear: 9999,
      onChange: this.props.onChange,
      onOpen: function (selectedDates, dateStr, instance) {
        const yearInput = instance.calendarContainer.querySelector(
          '.flatpickr-current-month .numInput'
        );
        const timeInput = instance.calendarContainer.querySelector(
          '.flatpickr-time .numInput'
        )

        yearInput.setAttribute('pattern', '[0-9]*');
        yearInput.setAttribute('min', 0);
        yearInput.setAttribute('max', 9999);

        yearInput.addEventListener('keypress', (e) => {
          if (!/^[0-9]+$/.test(e.key)) {
            e.preventDefault();
          }
        });

        yearInput.addEventListener('keydown', (e) => {
          if (e.keyCode === 8 || e.keyCode === 46) {
            return true;
          }
          if (!/^[0-9]+$/.test(e.key)) {
            e.preventDefault();
          }
        });

        yearInput.addEventListener('blur', (e) => {
          const yearValue = parseInt(e.target.value, 10);
          if (yearValue < 0 || yearValue > 9999 || isNaN(yearValue)) {
            yearInput.value = new Date().getFullYear().toString();
            instance.setDate('today');
            instance.redraw();
          } else {
            yearInput.value = yearValue.toString();
          }
        });

        timeInput.addEventListener('keypress', e => {
          if (!/^[0-9]+$/.test(e.key)) {
            e.preventDefault();
          }
        })
      },
    });

    document.addEventListener('submit', this.handleFormSubmit);
  }

  componentWillUnmount() {
    document.removeEventListener('submit', this.handleFormSubmit);
  }

  handleFormSubmit(event) {
    if (event.target.id === 'accounts-form') {
      const verifiedDateInput = document.querySelector('#verified-date-range');
      const verifiedDateField = document.querySelector('#verified-date-field');
      verifiedDateField.value = verifiedDateInput.value;
    }
  }

  render() {
    return (
      <div className='verified-date-container'>
        <input
          id='verified-date-range'
          ref={this.dateRangeRef}
          type='text'
          placeholder='Select date range'
          className='verified-date-input'
        />
        <div>{CalenderIcon}</div>
      </div>
    );
  }
}
