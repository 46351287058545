import React, { useEffect, useState } from 'react';
import EthereumIcon from '../../../../images/icons/icon_ethereum.png';
import CardanoIcon from '../../../../images/icons/icon_cardano.png';
import MetamaskIcon from '../../../../images/icons/icon_metamask.png';
import NamiIcon from '../../../../images/icons/icon_nami.png';
import TyphonIcon from '../../../../images/icons/icon_typhon.svg';
import PropTypes from 'prop-types';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { injected } from 'mastodon/containers/mastodon';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'mastodon/actions/modal';
import { UserRejectedRequestError } from '@web3-react/injected-connector';
import { walletConnected } from 'mastodon/settings';
import { CloseIcon } from 'mastodon/utils/icons';
import { useAppCardano } from 'mastodon/containers/cardano_context';
import { switchNetwork } from 'mastodon/components/on_chain';
import { showAlert, showAlertForError } from 'mastodon/actions/alerts';
import { EnablementFailedError } from '@cardano-foundation/cardano-connect-with-wallet';
import { isMobile } from 'mastodon/is_mobile';
import api from 'mastodon/api';
import { me } from 'mastodon/initial_state';

export const SUPPORTED_NETWORKS = {
  ethereum: 'ethereum',
  cardano: 'cardano',
};

export const SUPPORTED_WALLETS = {
  metamask: {
    name: 'Metamask',
    icon: MetamaskIcon,
    connectName: 'metamask',
  },
  nami: { name: 'Nami', icon: NamiIcon, connectName: 'nami' },
  typhon: { name: 'Typhon', icon: TyphonIcon, connectName: 'typhon' },
  // yoroi: { name: 'Yoroi', icon: NamiIcon, connectName: 'yoroi' },
};

const SUPPORTED_NETWORKS_CONFIG = {
  [SUPPORTED_NETWORKS.ethereum]: {
    icon: EthereumIcon,
    name: 'Ethereum',
  },
  [SUPPORTED_NETWORKS.cardano]: {
    icon: CardanoIcon,
    name: 'Cardano',
  },
};

const SUPPORTED_WALLETS_IN_NETWORK = {
  [SUPPORTED_NETWORKS.ethereum]: [SUPPORTED_WALLETS.metamask],
  [SUPPORTED_NETWORKS.cardano]: [
    SUPPORTED_WALLETS.nami,
    SUPPORTED_WALLETS.typhon,
    // SUPPORTED_WALLETS.yoroi,
  ],
};

export default function ConnectWalletModal() {
  const [selectedNetwork, setSelectedNetwork] = useState();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeModal('CONNECT_WALLET'));
  };

  return (
    <div className='modal-root__modal mega-boost-modal connect-wallet-modal'>
      {selectedNetwork ? (
        <SelecteWalletStep
          selectedNetwork={selectedNetwork}
          onClose={onClose}
        />
      ) : (
        <SelectNetworkStep
          setSelectedNetwork={setSelectedNetwork}
          onClose={onClose}
        />
      )}
    </div>
  );
}

function SelectNetworkStep({ setSelectedNetwork, onClose }) {
  return (
    <>
      <button className='close-icon close-modal-button' onClick={onClose}>
        {CloseIcon}
      </button>
      <p className='mega-boost-modal__title'>Select Network</p>
      <div className='connect-options'>
        {Object.entries(SUPPORTED_NETWORKS_CONFIG).map(
          ([key, { icon, name }]) => {
            return (
              <NetworkOption
                key={key}
                icon={icon}
                name={name}
                onClick={() => setSelectedNetwork(key)}
              />
            );
          }
        )}
      </div>
    </>
  );
}

const ERRORS = {
  walletNotDetect: 'WALLET_NOT_DETECT',
  networkError: 'NETWORK_ERROR',
  errorConnection: 'ERROR_CONNECTION',
};

const WALLET_DOWNLOAD_LINKS = {
  [SUPPORTED_WALLETS.metamask.connectName]: 'https://metamask.io',
  [SUPPORTED_WALLETS.nami.connectName]:
    'https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo?hl=en',
  [SUPPORTED_WALLETS.typhon.connectName]: 'https://typhonwallet.io/#/download',
  // [SUPPORTED_WALLETS.yoroi.connectName]: 'https://typhonwallet.io/#/download',
};

const ERROR_MESSAGES = {
  [ERRORS.walletNotDetect]: (wallet) => (
    <p className='text-error' style={{ color: '#FF7C73' }}>
      Unavailable extension.{' '}
      <a href={WALLET_DOWNLOAD_LINKS[wallet]} style={{ color: '#31B5FF' }}>
        Click here
      </a>{' '}
      to install
    </p>
  ),
  [ERRORS.errorConnection]: (wallet) => (
    <p className='text-error' style={{ color: '#FF7C73' }}>
      Error connecting to {SUPPORTED_WALLETS[wallet].name}. Try again.
    </p>
  ),
  [ERRORS.networkError]: () => (
    <p className='text-error' style={{ color: '#FF7C73' }}>
      Network is unsupported. Please switch to the appropriate Ethereum network.
    </p>
  ),
};

function SelecteWalletStep({ selectedNetwork, onClose }) {
  const wallets = SUPPORTED_WALLETS_IN_NETWORK[selectedNetwork];
  const dispatch = useDispatch();
  const [connectError, setConnectError] = useState();
  const [currentErrWallet, setCurrentErrWallet] = useState('');

  const { library, activate } = useWeb3React();
  const { connect, usedAddresses } = useAppCardano();

  const connectEthereumWallet = async () => {
    try {
      if (isMobile(window.innerWidth) && !window?.ethereum) {
        const metamaskAppDeepLink = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
        window.open(metamaskAppDeepLink);
        return;
      }
      await activate(injected, undefined, true);
      walletConnected.set(SUPPORTED_WALLETS.metamask.connectName, true);
    } catch (errors) {
      // alert(errors);
      if (errors instanceof UnsupportedChainIdError) {
        switchNetwork(library, dispatch);
      }
      if (errors instanceof UserRejectedRequestError) {
        dispatch(showAlert('', 'Request rejected'));
      }
      throw errors;
    }
  };

  const connectCardanoWallet = async (cardanoWallet) => {
    try {
      await connect(cardanoWallet, undefined, (err) => {
        console.log({ err });
        throw err;
      });

      walletConnected.set(SUPPORTED_NETWORKS.cardano, cardanoWallet);
      dispatch(
        showAlert(
          '',
          `Connected to ${SUPPORTED_WALLETS[cardanoWallet].name} wallet`
        )
      );
    } catch (err) {
      if (
        err.name === 'EnablementFailedError' ||
        err instanceof EnablementFailedError
      ) {
        setConnectError(ERRORS.errorConnection);
        setCurrentErrWallet(cardanoWallet);
      }
      throw err;
    }
  };

  const connectFn = {
    [SUPPORTED_NETWORKS.cardano]: connectCardanoWallet,
    [SUPPORTED_NETWORKS.ethereum]: connectEthereumWallet,
  };

  const handleConnectWallet = async (connectName) => {
    if (selectedNetwork === SUPPORTED_NETWORKS.ethereum) {
      const isMetamaskInstalled = window?.ethereum;
      if (!isMobile(window.innerWidth) && !isMetamaskInstalled) {
        // alert('Go here');
        setConnectError(ERRORS.walletNotDetect);
        setCurrentErrWallet(connectName);
        return;
      }
    }

    if (selectedNetwork === SUPPORTED_NETWORKS.cardano) {
      const isNamiInstalled = window?.cardano?.[connectName];
      if (!isNamiInstalled) {
        setConnectError(ERRORS.walletNotDetect);
        setCurrentErrWallet(connectName);
        return;
      }
    }

    try {
      await connectFn[selectedNetwork](connectName);
      dispatch(closeModal('CONNECT_WALLET'));
    } catch (err) {
      showAlertForError(err);
    }
  };

  return (
    <>
      <button className='close-icon close-modal-button' onClick={onClose}>
        {CloseIcon}
      </button>
      <p className='mega-boost-modal__title'>Select Wallet</p>
      <div className='connect-options'>
        {wallets.map(({ icon, name, connectName }, id) => (
          <React.Fragment key={id}>
            <NetworkOption
              icon={icon}
              name={name}
              onClick={() => handleConnectWallet(connectName)}
            />
            {!!connectError &&
              connectName === currentErrWallet &&
              ERROR_MESSAGES[connectError](connectName)}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

function NetworkOption({ icon, name, onClick }) {
  return (
    <button className='connect-option' onClick={onClick}>
      <img src={icon} alt={name} width={40} /> <span>{name}</span>
    </button>
  );
}

SelecteWalletStep.propTypes = {
  selectedNetwork: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

SelectNetworkStep.propTypes = {
  setSelectedNetwork: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

NetworkOption.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
