import React from 'react';

const Logo = () => (
  <svg
    width='161'
    height='44'
    viewBox='0 0 161 44'
    className='logo logo-word-mark'
    role='img'
  >
    <title>Mindplex</title>
    <use xlinkHref='#logo-symbol-wordmark' />
  </svg>
);

export default Logo;
