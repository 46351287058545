import React from 'react';
import VerifiedModal from '../verified_modal';
import PropTypes from 'prop-types';
import ModalRoot from '../modal_root';
import { addLocaleData, injectIntl } from 'react-intl';
import { getLocale } from 'mastodon/locales';
import { connect } from 'react-redux';
import { makeGetAccount } from 'mastodon/selectors';
import api from 'mastodon/api';
import { commentIcon } from 'mastodon/utils/icons';
import ImmutablePropTypes from 'react-immutable-proptypes';

const { localeData } = getLocale();
addLocaleData(localeData);

const makeMapStateToProps = () => {
  const getAccount = makeGetAccount();

  const mapStateToProps = (state, props) => {
    return {
      account: getAccount(state, props.accountId),
    };
  };

  return mapStateToProps;
};

export default
@injectIntl
@connect(makeMapStateToProps)
class VerifiedSwitch extends React.PureComponent {
  static propTypes = {
    account: ImmutablePropTypes.map,
    accountId: PropTypes.string,
    accountName: PropTypes.string,
    verified: PropTypes.bool,
    lastComment: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    verified: this.props.verified,
    isOpenModal: false,
    loading: false,
    verifiedTooltip: '',
  };

  handleToggle = () => {
    this.setState({ verified: !this.state.verified, isOpenModal: true });
  };

  handleCloseModal = () => {
    this.setState({ isOpenModal: false, verified: !this.state.verified });
  };

  updateDisplayLabel = (accountId, comment, display_label) => {
    api()
      .post(`/api/v1/admin/accounts/${accountId}/update_display_label`, {
        description: comment,
        display_label: display_label
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidMount() {
    this.setState({
      verifiedTooltip: this.state.verified
        ? 'Turn off verified label'
        : 'Turn on verified label',
    });
  }

  onSave = (comment) => {
    const { accountId } = this.props;

    this.updateDisplayLabel(accountId, comment, this.state.verified);
    this.setState({ isOpenModal: false });
  };

  render() {
    const { verified, verifiedTooltip, isOpenModal } = this.state;
    const { accountName, lastComment } = this.props;

    return (
      <div>
        <div className="verified-switch">
          <div className="verified-switch__btn" data-title={verifiedTooltip}>
            <label className="switch">
              <input
                type="checkbox"
                checked={verified}
                onChange={this.handleToggle}
              />
              <span className="slider round" />
            </label>
            <p className="verified-tooltip">{verifiedTooltip}</p>
          </div>
          {lastComment && (
            <div className="verified-comment-wrap">
              <span className="verified-comment-wrap__comment-icon">
                {commentIcon}
              </span>

              <p className="verified-tooltip verified-comment-tooltip">
                <p className="title">Latest comment:</p>
                <span>{lastComment.description}</span>
              </p>
            </div>
          )}
        </div>
        <ModalRoot onClose={this.handleCloseModal}>
          {isOpenModal && (
            <VerifiedModal
              onClose={this.handleCloseModal}
              onSave={this.onSave}
              verified={verified}
              accountName={accountName}
            />
          )}
        </ModalRoot>
      </div>
    );
  }
}
