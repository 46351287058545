import { Map as ImmutableMap } from 'immutable';
import { FETCH_MPX_BALANCE_SUCCESS } from 'mastodon/actions/mpx_balance';

const initialState = ImmutableMap();

export default function mpx_balance(state = initialState, action) {
  switch (action.type) {
    case FETCH_MPX_BALANCE_SUCCESS:
      return state.set('mpx_balance', action.mpx_balance);
    default:
      return state;
  }
}
