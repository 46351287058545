import api, { getLinks } from '../api';

export const BALANCE_HISTORY_FETCH_REQUEST = 'BALANCE_HISTORY_FETCH_REQUEST';
export const BALANCE_HISTORY_FETCH_SUCCESS = 'BALANCE_HISTORY_FETCH_SUCCESS';
export const BALANCE_HISTORY_FETCH_FAIL = 'BALANCE_HISTORY_FETCH_FAIL';

export const BALANCE_HISTORY_EXPAND_REQUEST = 'BALANCE_HISTORY_EXPAND_REQUEST';
export const BALANCE_HISTORY_EXPAND_SUCCESS = 'BALANCE_HISTORY_EXPAND_SUCCESS';
export const BALANCE_HISTORY_EXPAND_FAIL = 'BALANCE_HISTORY_EXPAND_FAIL';

export const ITEM_PER_PAGE = 15;

export const fetchBalanceHistory =
  ({
    accountId,
    currency,
    actionType,
    networkType,
    currentPage,
    selectedDates,
    sortTime,
    sortBalance,
  }) =>
  (dispatch, getState) => {
    const loading = getState().getIn(['balance_history', 'isLoading']);

    if (loading) {
      return;
    }

    dispatch(fetchBalanceHistoryRequest(accountId));

    api(getState)
      .get(`/api/v1/accounts/${accountId}/balance_histories`, {
        params: {
          currency,
          per_page: ITEM_PER_PAGE,
          action_type: actionType,
          network: networkType,
          page: currentPage,
          start_date: selectedDates[0],
          end_date: selectedDates[1],
          sort_time: sortTime,
          sort_balance: sortBalance,
        },
      })
      .then(({ data }) => {
        dispatch(fetchBalanceHistorySuccess(accountId, data));
      })
      .catch((error) => dispatch(fetchBalanceHistoryFail(error)));
  };

export const fetchBalanceHistoryRequest = (accountId) => ({
  type: BALANCE_HISTORY_FETCH_REQUEST,
  accountId,
});

export const fetchBalanceHistorySuccess = (accountId, balance_history) => ({
  type: BALANCE_HISTORY_FETCH_SUCCESS,
  accountId,
  balance_history,
});

export const fetchBalanceHistoryFail = (error) => ({
  type: BALANCE_HISTORY_FETCH_FAIL,
  error,
});

export function expandBalanceHistoryRequest() {
  return {
    type: BALANCE_HISTORY_EXPAND_REQUEST,
  };
}

export function expandBalanceHistorySuccess(domains, next) {
  return {
    type: BALANCE_HISTORY_EXPAND_SUCCESS,
    domains,
    next,
  };
}

export function expandBalanceHistoryFail(error) {
  return {
    type: BALANCE_HISTORY_EXPAND_FAIL,
    error,
  };
}
