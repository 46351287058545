import { Map as ImmutableMap, fromJS } from 'immutable';
import {
  ACCOUNT_DAILY_STATS_FETCH_FAIL,
  ACCOUNT_DAILY_STATS_FETCH_SUCCESS,
} from 'mastodon/actions/mega_boost';

const initialState = ImmutableMap({
  account_daily_stats: ImmutableMap(),
});

export default function mega_boost(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_DAILY_STATS_FETCH_SUCCESS:
      return state.set('account_daily_stats', {
        app_config: {
          max_limit_mpx: 432424325253253.3,
          max_mega_boost_toots: 10,
        },
        mega_boost_spent: 0,
        mpx_spent: 0,
        mega_boost_remain: 10,
        mpx_remain: 432424325253253.3,
      });
    case ACCOUNT_DAILY_STATS_FETCH_FAIL:
      return state.set(action.error, false);
    default:
      return state;
  }
}
