import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Logo from 'mastodon/components/logo';
import { timelinePreview, showTrends } from 'mastodon/initial_state';
import ColumnLink from './column_link';
import DisabledAccountBanner from './disabled_account_banner';
import FollowRequestsColumnLink from './follow_requests_column_link';
import ListPanel from './list_panel';
import SignInBanner from './sign_in_banner';
import NavigationPortal from 'mastodon/components/navigation_portal';
import {
  BalanceHistoryIcon,
  BookMarkIcon,
  ExploreIcon,
  FederatedIcon,
  HomeIcon,
  ListsIcon,
  LocalIcon,
  MoreHorizontalIcon,
  PreferencesIcon,
  PrivateMentionIcon,
  SearchIcon,
  StarIcon,
} from 'mastodon/utils/icons';
import Notifications_counter_icon from './notifications_counter_icon';

const messages = defineMessages({
  home: { id: 'tabs_bar.home', defaultMessage: 'Home' },
  notifications: {
    id: 'tabs_bar.notifications',
    defaultMessage: 'Notifications',
  },
  explore: { id: 'explore.title', defaultMessage: 'Explore' },
  local: { id: 'tabs_bar.local_timeline', defaultMessage: 'Local' },
  federated: { id: 'tabs_bar.federated_timeline', defaultMessage: 'Federated' },
  direct: { id: 'navigation_bar.direct', defaultMessage: 'Private mentions' },
  favourites: { id: 'navigation_bar.favourites', defaultMessage: 'Favourites' },
  bookmarks: { id: 'navigation_bar.bookmarks', defaultMessage: 'Bookmarks' },
  lists: { id: 'navigation_bar.lists', defaultMessage: 'Lists' },
  preferences: {
    id: 'navigation_bar.preferences',
    defaultMessage: 'Preferences',
  },
  followsAndFollowers: {
    id: 'navigation_bar.follows_and_followers',
    defaultMessage: 'Follows and followers',
  },
  about: { id: 'navigation_bar.about', defaultMessage: 'About' },
  search: { id: 'navigation_bar.search', defaultMessage: 'Search' },
  balance_history: {
    id: 'navigation_bar.balance_history',
    defaultMessage: 'Balance History',
  },
});

export default
@injectIntl
class NavigationPanel extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    identity: PropTypes.object.isRequired,
  };

  static propTypes = {
    intl: PropTypes.object.isRequired,
    getClaimInfo: PropTypes.func,
    isShowBalanceHistory: PropTypes.bool,
  };

  render() {
    const { intl } = this.props;
    const { signedIn, disabledAccountId } = this.context.identity;

    return (
      <div className='navigation-panel'>
        <div className='navigation-panel__logo'>
          <Link to='/' className='column-link column-link--logo'>
            <Logo />
          </Link>
        </div>
        <div className='search-in-nav'>
          <ColumnLink
            transparent
            to='/search'
            icon={<span className='column-link__icon'>{SearchIcon}</span>}
          />
        </div>
        {signedIn && (
          <React.Fragment>
            <ColumnLink
              transparent
              to='/home'
              icon={<span className='column-link__icon'>{HomeIcon}</span>}
              text={intl.formatMessage(messages.home)}
            />
            <ColumnLink
              transparent
              to='/notifications'
              icon={<Notifications_counter_icon />}
              text={intl.formatMessage(messages.notifications)}
            />
            <FollowRequestsColumnLink />
          </React.Fragment>
        )}

        {showTrends ? (
          <ColumnLink
            transparent
            to='/explore'
            icon={<span className='column-link__icon'>{ExploreIcon}</span>}
            text={intl.formatMessage(messages.explore)}
          />
        ) : (
          <ColumnLink
            transparent
            to='/search'
            icon='search'
            text={intl.formatMessage(messages.search)}
          />
        )}

        {(signedIn || timelinePreview) && (
          <>
            <ColumnLink
              transparent
              to='/public/local'
              icon={
                <span className='column-link__icon local-icon'>
                  {LocalIcon}
                </span>
              }
              text={intl.formatMessage(messages.local)}
            />
            <ColumnLink
              transparent
              exact
              to='/public'
              icon={<span className='column-link__icon'>{FederatedIcon}</span>}
              text={intl.formatMessage(messages.federated)}
            />
          </>
        )}

        {!signedIn && (
          <div className='navigation-panel__sign-in-banner'>
            <hr />
            {disabledAccountId ? <DisabledAccountBanner /> : <SignInBanner />}
          </div>
        )}

        {signedIn && (
          <React.Fragment>
            <ColumnLink
              transparent
              to='/conversations'
              icon={
                <span className='column-link__icon'>{PrivateMentionIcon}</span>
              }
              text={intl.formatMessage(messages.direct)}
            />
            <ColumnLink
              transparent
              to='/bookmarks'
              icon={<span className='column-link__icon'>{BookMarkIcon}</span>}
              text={intl.formatMessage(messages.bookmarks)}
            />
            <ColumnLink
              transparent
              to='/favourites'
              icon={
                <span className='column-link__icon star_icon'>{StarIcon}</span>
              }
              text={intl.formatMessage(messages.favourites)}
            />
            {this.props.isShowBalanceHistory && (
              <ColumnLink
                transparent
                to='/balance-history'
                icon={
                  <span className='column-link__icon'>
                    {BalanceHistoryIcon}
                  </span>
                }
                text={intl.formatMessage(messages.balance_history)}
              />
            )}
            <ColumnLink
              transparent
              to='/lists'
              icon={<span className='column-link__icon'>{ListsIcon}</span>}
              text={intl.formatMessage(messages.lists)}
            />

            <ListPanel />

            <hr />

            <ColumnLink
              transparent
              href='/settings/preferences'
              icon={
                <span className='column-link__icon'>{PreferencesIcon}</span>
              }
              text={intl.formatMessage(messages.preferences)}
            />
          </React.Fragment>
        )}

        <div className='navigation-panel__legal'>
          <hr />
          <ColumnLink
            transparent
            to='/about'
            icon={MoreHorizontalIcon}
            text={intl.formatMessage(messages.about)}
          />
        </div>

        <NavigationPortal />
      </div>
    );
  }
}
