import React, { useEffect, useRef, useState } from 'react';
import Button from './button';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { injected } from 'mastodon/containers/mastodon';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'mastodon/actions/modal';
import EthereumIcon from '../../images/icons/icon_ethereum.png';
import CardanoIcon from '../../images/icons/icon_cardano.png';
import ArrowDownIcon from '../../images/icons/icon_arrow_down.png';
import ClaimReward from './claim_reward';
import { getMPXBalance } from 'mastodon/actions/mpx_balance';
import MPXABI from 'mastodon/abis/MindplexUpgradeableToken.json';
import { walletConnected } from 'mastodon/settings';
import {
  SUPPORTED_NETWORKS,
  SUPPORTED_WALLETS,
} from 'mastodon/features/ui/components/connect_wallet_modal';
import Web3 from 'web3';
import { showAlert } from 'mastodon/actions/alerts';
import { OutlineWallet } from 'mastodon/utils/icons';
import { useAppCardano } from 'mastodon/containers/cardano_context';
import api from 'mastodon/api';
import { me } from 'mastodon/initial_state';

const messages = defineMessages({
  totalMPX: { id: 'navigation_bar.total_mpx', defaultMessage: 'Total MPX:' },
  totalMPXR: { id: 'navigation_bar.total_mpxr', defaultMessage: 'Total MPXR:' },
  connectWallet: {
    id: 'navigation_bar.connect_wallet',
    defaultMessage: 'Connect Wallet',
  },
  connect: {
    id: 'navigation_bar.connect',
    defaultMessage: 'Connect',
  },
  wrongNetwork: {
    id: 'navigation_bar.wrong_network',
    defaultMessage: 'Wrong network',
  },
});

const useCurrentNetwork = () => {
  const { active, account, deactivate } = useWeb3React();
  const { isEnabled, stakeAddress, disconnect } = useAppCardano();

  if (active) {
    return {
      isWalletConnected: true,
      network: SUPPORTED_NETWORKS.ethereum,
      account,
      icon: EthereumIcon,
      disconnect: () => {
        deactivate();
        walletConnected.remove(SUPPORTED_WALLETS.metamask.connectName);
      },
    };
  }

  if (isEnabled) {
    return {
      isWalletConnected: true,
      network: SUPPORTED_NETWORKS.cardano,
      account: stakeAddress,
      icon: CardanoIcon,
      disconnect: () => {
        disconnect();
      },
    };
  }

  return { isWalletConnected: false };
};

function OnChain({ signedIn, intl }) {
  const dispatch = useDispatch();
  const { library, account, error } = useWeb3React();
  const { disconnect, isWalletConnected } = useCurrentNetwork();

  useEffect(() => {
    if (library && account) {
      dispatch(getMPXBalance({ library, mpxAbi: MPXABI, account }));
    }
  }, [library, account, dispatch]);

  useEffect(() => {
    if (!signedIn && isWalletConnected) {
      disconnect();
    }
  }, [signedIn, isWalletConnected]);

  const eagerConnect = useEagerConnect();
  useInactiveListener(eagerConnect);

  if (isWalletConnected) {
    return (
      <>
        <ClaimReward signedIn={signedIn} classNames='balance-reward-side' />
        <AddressButton />
      </>
    );
  }

  if (error instanceof UnsupportedChainIdError) {
    return (
      <Button block onClick={() => switchNetwork(library, dispatch)}>
        {intl.formatMessage(messages.wrongNetwork)}
      </Button>
    );
  }

  return (
    signedIn && (
      <>
        <Button
          block
          className='connect-wallet-side'
          onClick={() => dispatch(openModal('CONNECT_WALLET', {}))}
        >
          {intl.formatMessage(messages.connectWallet)}
        </Button>
        <Button
          secondary
          className='connect-wallet-header'
          onClick={() => dispatch(openModal('CONNECT_WALLET', {}))}
        >
          <span>{OutlineWallet}</span>
          <span>{intl.formatMessage(messages.connect)}</span>
        </Button>
      </>
    )
  );
}

function AddressButton() {
  const { active: isEtherActive, account } = useWeb3React();
  const { isEnabled, usedAddresses } = useAppCardano();
  const dispatch = useDispatch();

  const userAcc = useSelector((state) => state.getIn(['accounts', me]));
  const firstUpdate = useRef(true);

  const registerPublicAddress = (publicAddress) => {
    api()
      .post(`/api/v1/accounts/${userAcc.get('id')}/register_public_address`, {
        public_address: publicAddress,
      })
      .then((response) => {
        // console.log({ response });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if ((isEnabled || isEtherActive) && (usedAddresses[0] || account)) {
      registerPublicAddress(isEnabled ? usedAddresses[0] : account);
    }
  }, [usedAddresses[0], account]);

  if (isEtherActive) {
    return (
      <div className='address-btn'>
        <button onClick={() => dispatch(openModal('DISCONNECT_WALLET', {}))}>
          <img
            src={EthereumIcon}
            alt='ethereum address'
            width={28}
            height={28}
          />
          <span className='address-web'>{ellipsisAddress(account)}</span>
          <span className='address-mobile'>
            {ellipsisAddress(account, 2, 2)}
          </span>
          <img className='address-btn-icon_down' src={ArrowDownIcon} alt='' />
        </button>
      </div>
    );
  }

  if (isEnabled) {
    return (
      <div className='address-btn'>
        <button onClick={() => dispatch(openModal('DISCONNECT_WALLET', {}))}>
          <img src={CardanoIcon} alt='cardano address' width={28} height={28} />
          <span className='address-web'>
            {ellipsisAddress(usedAddresses?.[0] || '')}
          </span>
          <span className='address-mobile'>
            {ellipsisAddress(usedAddresses?.[0] || '', 2, 2)}
          </span>
          <img className='address-btn-icon_down' src={ArrowDownIcon} alt='' />
        </button>
      </div>
    );
  }

  return null;
}

export function ellipsisAddress(address, firstChars = 6, lastChars = 4) {
  return `${address.slice(0, firstChars)}...${address.slice(-lastChars)}`;
}

OnChain.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(OnChain);

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const { connect } = useAppCardano();

  const [tried, setTried] = useState(false);
  const metamaskConnected = walletConnected.get(
    SUPPORTED_WALLETS.metamask.connectName
  );
  const cardanoWalletConnected = walletConnected.get(
    SUPPORTED_NETWORKS.cardano
  );

  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (isAuthorized && metamaskConnected) {
          activate(injected)
            .then(() =>
              walletConnected.set(SUPPORTED_WALLETS.metamask.connectName, true)
            )
            .catch(() => {
              setTried(true);
              walletConnected.remove(SUPPORTED_WALLETS.metamask.connectName);
            });
        } else {
          setTried(true);
        }
      })
      .catch();
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  useEffect(() => {
    if (cardanoWalletConnected) {
      connect(cardanoWalletConnected);
    }
  }, [cardanoWalletConnected]);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React();
  const { provider, connect, currentWallet } = useAppCardano();

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        activate(injected);
      };
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = () => {
        activate(injected);
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);

  // TODO: update wallet name
  useEffect(() => {
    if (currentWallet === SUPPORTED_WALLETS.nami.connectName) {
      if (provider?.experimental) {
        provider.experimental.on('accountChange', () => {
          connect(currentWallet);
        });
      }
    }
    if (provider?.on) {
      provider.on('accountChanged', () => {
        // alert('accountChanged');
        connect(currentWallet);
      });
    }
  }, [provider]);
}

export async function switchNetwork(externalProvider, dispatch) {
  const provider = externalProvider || window.ethereum;

  const web3 = new Web3(provider);

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(injected.supportedChainIds[0]) }],
      });
    } catch (switchError) {
      dispatch(showAlert('Error connecting to MetaMask. Try again.', ''));
    }
  } else {
    dispatch(showAlert('Error connecting to MetaMask. Try again.', ''));
    console.error(
      `Can't setup network on metamask because window.ethereum is undefined`
    );
  }
}
