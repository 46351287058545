import Web3 from 'web3';
import { MEGA_BOOST_ADDRESS, MPX_ADDRESS } from './config';
import MPXABI from 'mastodon/abis/MindplexUpgradeableToken.json';
import MegaBoostABI from 'mastodon/abis/MegaBoost.json';

export const getMPXContract = (library) => {
  const web3 = new Web3(library.provider);

  return new web3.eth.Contract(MPXABI, MPX_ADDRESS);
};

export const getMegaBoostContract = (library) => {
  const web3 = new Web3(library.provider);

  return new web3.eth.Contract(MegaBoostABI, MEGA_BOOST_ADDRESS);
};
